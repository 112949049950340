@import './shared-variables.scss';
.concepts {
  margin: 32px 56px auto;
  padding-bottom: 56px + $basicFooter;
  display: flex;
  flex-direction: column;
  .search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    .title {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: $subtitle-color;
    }
    .element {
      font-size: 14px;
      line-height: 20px;
      color: $dark-color;
      margin-left: 20px;
    }
    .active {
      font-weight: 600;
    }
    &-left-side {
      .element {
        margin-left: 12px;
      }
    }
    &-right-side-responsive {
      display: none;
    }
  }
  .offers {
    display: flex;
    flex-wrap: wrap;
    .offer-wrapper {
      margin-right: 32px;
      margin-bottom: 32px;
    }
  }
  .pagination {
    justify-content: flex-end;
  }
}
@media screen and (max-width: 680px) {
  .concepts {
    padding-bottom: 0;
    .offers {
      .offer-wrapper {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 610px) {
  .concepts {
    margin: 16px;
    .search {
      &-left-side {
        .title {
          display: none;
        }
      }
      &-right-side {
        display: none;
      }
      &-right-side-responsive {
        display: block;
        margin-left: 2px;
      }
    }
  }
}
