.concept-demo {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr 2fr 1fr;
  justify-items: start;
  align-items: center;
  padding: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $dark-color;
  .type {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-right: 16px;
  }
  .file {
    justify-self: start;
  }
  .file-data {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $subtitle-color;
    justify-self: end;
  }
  .icon {
    justify-self: end;
    cursor: pointer;
    img {
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 768px) {
  .concept-demo {
    grid-template-columns: 4fr 1fr;
    .type {
      display: none;
    }
    .file-data {
      display: none;
    }
  }
}
