@import './shared-variables.scss';

.App {
  text-align: center;
  background: #fbfbfb;
  font-family: $main-font;
  position: relative;
  min-height: 100vh;
  .commonInfo {
    position: absolute;
    top: 114px;
    right: 20px;
    @include for-phone-and-tablet {
      display: none;
    }
  }
}
.tooltip-block {
  &:hover {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 100;
    opacity: 0.8;
    .tooltip-text,
    .tooltip-arrow {
      display: block;
    }
  }
  @include for-phone-and-tablet {
    & {
      display: none;
    }
  }
}
.faberling_icon {
  width: 24px;
  height: 24px;
}
.tooltip-text {
  display: none;
  position: absolute;
  background-color: $dark-color;
  color: $light-color;
  padding: 8px;
  right: -200px;
  top: 12px;
  border-radius: $border-radius;
  text-align: left;
  width: 192px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  z-index: 100;
}
.tooltip-arrow {
  display: none;
  position: absolute;
  top: 17px;
  right: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid $dark-color;
}
.error-be {
            min-height: 14px;
            margin-top: 32px;
            color: #eb5757;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
          }
@media (max-width: 725px) {
  body {
    // width: 71.7vh;
  }
}
.MuiBox-root {
  .MuiLinearProgress-barColorPrimary {
    background-color: black;
  }
  .MuiLinearProgress-colorPrimary {
    background-color: #999999;
  }
}