.modal-terms {
  padding: 20px;
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark{
     margin-bottom: 16px;
    padding: initial;
    border: initial;
    font-size: initial;
    font-family: sans-serif;
    font: auto;
    vertical-align: initial;
    white-space: initial;
  }
  h2 {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 30px;
    margin-top: 21px;
  }
  strong {
      font-weight: 700;
  }
}
