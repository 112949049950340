.page {
  &-wrapper {
    margin: 0 auto;
    width: 60%;
  }
  &-block {
    &:not(:first-child) {
      margin-top: 56px;
    }
    h2 {
      margin-bottom: 40px;
    }
  }
}
.selectable-blocks {
  display: flex;
  flex-direction: column;

  &-wrapper {
    margin-top: 32px;

    &:first-child {
      margin-top: 0;
    }
  }

  &-item {
    align-items: center;
    background-color: $light-color;
    border: 1px solid $card-border;
    border-radius: $border-radius;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    margin: 4px 0;

    p {
      text-align: start;
    }

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    &.column-direction {
      flex-direction: column;
      &:first-child {
        align-items: flex-start;
      }
    }
  }

  &-checkboxes {
    display: flex;
  }
}
hr {
  border-color: $card-border;
  margin: 24px 0;
}
.iframe-wrapper {
  min-height: 735px;
  iframe {
    height: 735px;
  }
}
