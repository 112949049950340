@import './shared-variables.scss';

.header {
  background-color: $light-color;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.04);
  padding-top: 18px;
  padding-bottom: 18px;
  letter-spacing: 0.5px;
  a {
    color: $dark-color;
  }
  a:hover {
    text-decoration: none;
  }

  .active {
    position: relative;
    font-weight: 600;
    line-height: 24px;
  }
  .projectStep {
    cursor: pointer;
  }
  .active:after {
    display: block;
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    background-color: $dark-color;
    left: 0;
    bottom: -21px;
    border-radius: 1px;
  }

  &-main {
    display: flex;
    justify-content: space-between;
  }
  &-main-responsive {
    display: none;
  }
  .left-side {
    display: flex;
    align-items: center;
    margin-left: 60px;
    color: $gray-color;
    p {
      margin-left: 20px;
    }
    .in-profile {
      display: flex;
      align-items: center;
      p {
        font-size: 16px;
        line-height: 24px;
        color: $dark-color;
        letter-spacing: 1px;
        border-bottom: 1px solid $dark-color;
        text-transform: uppercase;
        margin-right: 10px;
      }
    }
    a {
      display: flex;
    }

    .designer {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      color: $dark-color;
      font-weight: bold;
      margin-left: 8px;
    }
  }
  .central-side {
    display: flex;
    align-items: center;
    div {
      margin-right: 32px;
    }
    div:last-child {
      margin-right: 0;
    }
  }

  .right-side {
    display: flex;
    align-items: center;
    margin-right: 60px;
    min-height: 27px;
    p {
      font-size: 12px;
      letter-spacing: 0.4px;
      color: $gray-color;
    }
    .sign-in,
    .profileHeader {
      margin-left: 20px;
      display: flex;
      align-items: center;
      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: $dark-color;
        margin-right: 10px;
      }
    }
    .profileHeader {
      margin-left: 60px;
      position: relative;
      p {
        margin-left: 10px;
      }
    }
    .notify {
      position: relative;
      .count {
        position: absolute;
        min-width: 18px;
        min-height: 15px;
        font-size: 11px;
        line-height: 15px;
        bottom: 0;
        right: -8px;
        color: white;
        background-color: black;
        border-radius: $border-radius / 4;
        text-align: center;
      }
    }
    a {
      display: flex;
    }
  }
  .header-additional-responsive {
    display: none;
  }

  &-additional {
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
    &-left-side {
      margin-left: 60px;
      h1 {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.8px;
        text-align: left;
      }
      .links {
        margin-top: 42px;
        display: flex;
        line-height: 24px;
        .link {
          // margin-right: 80px;
          margin-right: 32px;
        }
        .active:after {
          bottom: -20px;
        }
        .link.company-role {
          margin-right: 24px;
        }
      }
      .offer-container {
        display: flex;
        margin-right: 24px;
        .offer {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 1px;
          text-transform: uppercase;
          margin-left: 10px;
          padding-right: 24px;
          border-right: 1px solid $light-gray;
          cursor: pointer;
        }
      }

      .winner-title.company-role {
        order: 4;
      }
      .concepts-title.company-role {
        order: 1;
      }
      .communication-title.company-role {
        order: 2;
      }
      .briefing-title.company-role {
        order: 0;
      }
    }

    &-right-side {
      margin-right: 60px;
      .status {
        text-transform: lowercase;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        border-radius: 1px;
        padding: 2px 4px;
        &.ON_MODERATION {
          color: #e86d00;
          background: #fcede0;
        }
        &.REVIEW {
          color: #e80000;
          background: #fce0e0;
        }
        &.ACCEPTED {
          color: #219653;
          background: #e4f2ea;
        }
        &.faberling-coach {
          color: #e80000;
          background: #fce0e0;
        }
        &.CLOSED {
          background: $card-border;
          font-size: 12px;
          line-height: 16px;
        }
        &.DECLINED {
          background: $card-border;
          font-size: 12px;
          line-height: 16px;
        }
      }
      .type {
        margin-right: 45px;
        font-size: 14px;
        line-height: 20px;
        padding: 2px 8px;
        color: $light-color;
        background: $dark-color;
      }
      .general-info {
        margin-top: 24px;
        display: flex;
        &-element {
          display: flex;
          flex-direction: column;
          text-align: left;
          margin-right: 40px;
          span {
            line-height: 24px;
            letter-spacing: 0.4px;
          }
        }
        &-element:last-child {
          margin-right: 0;
        }
        .title {
          font-size: 11px;
          line-height: 12px;
          font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
          color: $subtitle-color;
          margin-bottom: 4px;
        }
        .data,
        .amount {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;

          letter-spacing: 0.4px;
        }
      }
    }
  }

  .header-menu-responsive.false {
    display: none;
  }

  .header-menu-responsive.true {
    display: block;
  }
}

.header-navigation-responsive {
  display: none;
}

@media screen and (max-width: 768px) {
  .header {
    &-main {
      .left-side {
        margin-left: 16px;
      }
      .right-side {
        margin-right: 16px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .header {
    padding-bottom: 0;
    padding-top: 0;
    &-main {
      display: none;
    }
    &-main-responsive {
      display: block;
      padding: 16px 16px;
      .main {
        margin-left: 0;
        display: flex;
        justify-content: space-between;
        .right-side,
        .left-side {
          margin: 0;
        }
        .right-side {
          display: flex;
          .notify {
            margin-right: 60px;
          }
          > img {
            position: absolute;
            top: 0;
            right: 0;
            padding: 16px;
          }
        }
        .user-name {
          display: flex;
          align-items: center;
          p {
            margin-left: 8px;
          }
        }
      }
    }
    &-main-responsive.true {
      background: $background-color;
    }
    &-menu-responsive {
      margin-top: 88px;
      background: $light-color;
      .title {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 52px;
      }
      .create-project {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        width: 61.25%;
        margin: 0 auto;
        a {
          background: $dark-color;
          color: $light-color;
          display: flex;
          justify-content: center;
          padding: 16px;
          border-radius: 4px;
          svg {
            margin-left: 11px;
          }
        }
      }
      .menu-footer {
        margin-top: 88px;
        display: flex;
        justify-content: space-between;
        background: $background-color;
        padding: 16px 32px;
        .profile,
        .log-out {
          display: flex;
          svg {
            margin-right: 9px;
            width: 23px;
            height: 23px;
          }
          a {
            display: inline-flex;
          }
        }
        p {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.5px;
        }
        .log-out {
          color: $error-color;
          svg {
            fill: $error-color;
            margin-right: 8px;
          }
        }
        .sign-in {
          width: 100%;
          a {
            justify-content: center;
            display: flex;
            img {
              margin-left: 8px;
            }
          }
        }
      }
    }
    .header-additional {
      &-right-side {
        .type {
          margin-right: 25px;
        }
      }
      &-right-side.hide-right-side {
        display: none;
      }
      .links {
        padding-bottom: 20px;
        overflow: auto;
      }
    }
  }
  .detailed-info-wrapper {
    .concepts {
      .offers {
        justify-content: center;
        .offer-wrapper {
          margin-bottom: 12px;
          width: 288px;
        }
      }
    }
  }
  .offer-container {
    svg {
      display: none;
    }
  }
  .header-navigation-responsive {
    display: block;
    overflow: auto;

    .active:after {
      bottom: -17px;
    }
  }
  .central-side {
    padding-bottom: 17px;
    margin-left: 16px;
    margin-right: 16px;
    width: 400px;
  }
}
