.not-found {
  margin: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  font-size: 32px;
  line-height: 40px;
  font-family: "Source Sans Pro";
  letter-spacing: 0.8px;
}
