@import './shared-variables.scss';

.toggle-switch {
  display: inline-block;
  height: 12px;
  position: relative;
  width: 44px;

  &.rounded {
    .slider {
      border-radius: 15rem;
      background: $light-gray;
      &:before {
        border-radius: 50%;
      }
      &:hover {
        background: $subtitle-color;
      }
    }
  }

  &-wrapper {
    height: 12px;
    position: relative;
    width: 44px;

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: 0.4s ease;

      &:before {
        width: 24px;
        height: 24px;
        position: absolute;
        background: $light-color;
        content: '';
        margin: 0;
        padding: 0;
        top: 50%;
        left: -1px;
        transform: translateY(-50%);
        transition: 0.4s;
        cursor: pointer;
        box-shadow: 0px 1px 2px $shadow-gray;
        border: 1px solid $light-gray;
      }

      &.isChecked {
        background: $light-gray;

        &:before {
          left: calc(100% - -2px - 25px);
          background: $dark-color;
        }
        &:hover {
          background: $subtitle-color;
        }
      }
    }
  }
}
