@mixin for-phone {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet {
  @media (min-width: 600px) and (max-width: 768px) {
    @content;
  }
}
@mixin for-phone-and-tablet {
  @include for-phone {
    @content;
  }
  @include for-tablet {
    @content;
  }
}

@mixin admin-table-only {
  @media (max-width: 1200px) {
    @content;
  }
}
