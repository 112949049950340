@import "./shared-variables.scss";

.single-slider-wrapper {
  margin: 20px 0;
  position: relative;
  width: 100%;
  &::before {
    content: "< 50'000";
    left: 0px;
    position: absolute;
    top: 20px;
  }
  &::after {
    content: "> 5 Mio";
    position: absolute;
    right: 0px;
    top: 20px;
  }
  .rc-slider {
    .rc-slider-rail {
      background: $light-color;
      border: 1px solid $light-gray;
      box-sizing: border-box;
      border-radius: $border-radius * 4;
      height: 8px;
    }
    .rc-slider-track {
      background-color: $dark-color;
      border-radius: $border-radius * 4;
      height: 8px;
    }
    .rc-slider-handle {
      background: $dark-color;
      border: solid 2px $dark-color;
      border-radius: $border-radius;
      height: 24px;
      margin-top: -8px;
      outline: none;
      width: 24px;

      &-dragging.rc-slider-handle-2.rc-slider-handle,
      &-dragging.rc-slider-handle-1.rc-slider-handle {
        border: solid 2px $dark-color;
        border-radius: $border-radius;
        box-shadow: none;
      }

      &::after {
          content: ">";
          color: $light-color;
          position: absolute;
          top: 1px;
          left: 5px;
        }
    }
  }
}
