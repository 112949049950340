@import './shared-variables.scss';

.btnWrapper {
  width: inherit;
  .button {
    border-radius: 4px;
    min-width: 168px;
    width: 100%;
    height: 52px;
    padding: 14px;
    outline: none;
    border: none;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    box-sizing: border-box;
    border: 1px solid $dark-color;
    img {
      margin-left: 0.5rem;
    }
    &.btnDisabled {
      background: rgba(0, 0, 0, 0.4);
      border: 1px solid $disable-color;
      color: $light-color;
    }
    &.btnActive {
      color: $light-color;
      border: 1px solid $light-color;
      background: $dark-color;
      transition: all 0.2s;
      &:hover {
        color: $dark-color;
        background: $light-color;
        border: 1px solid $dark-color;
        transition: all 0.2s;
        svg {
          fill: $dark-color;
        }
      }
    }
    svg {
      margin-left: 10px;
      fill: $light-color;
    }
  }
}

.smallButton {
  cursor: pointer;
  border-radius: 4px;
  max-width: max-content;
  height: 52px;
  padding: 14px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  background: $dark-color;
  border-radius: 4px;
  padding: 8px 16px;
  height: 36px;
  align-self: center;
  color: $light-color;
  outline: none;
  border: none;
  &:hover {
    color: $dark-color;
    background: $light-color;
    border: 1px solid $dark-color;
  }
  &:focus {
    outline: none;
  }
}
