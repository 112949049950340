@import './shared-variables.scss';

.page-wrapper {
  width: 91%;
  margin: 56px auto auto;
  display: flex;
  flex-direction: column;
  .transactionsWrapper, .transactionsWrapper-admin, .transactionsWrapper-designer {
    text-align: left;
    h1 {
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.8px;
      color: $dark-color;
    }
    .table-wrapper {
      margin-top: 32px;
      .table-item:nth-child(even) {
        background: $light-color;
      }
      ul {
        .tableHeader {
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 12px;
          font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
          color: $subtitle-color;
          background: $card-border;
          border-radius: 4px;
          padding: 6px 24px;
          display: grid;
          grid-template-rows: 1fr;
          grid-template-columns: 1.3fr 1.2fr 0.5fr 2.7fr 2fr 0.7fr 0.7fr;
          grid-gap: 24px;
          align-items: center;
          @include for-phone-and-tablet {
            display: none;
          }
          span {
            text-align: left;
          }
          .category {
            padding-left: 20%;
          }
        }
        .table-item {
          box-sizing: border-box;
          color: $dark-color;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          padding: 16px 24px;
          display: grid;
          grid-template-rows: 1fr;
          grid-template-columns: 1.3fr 1.2fr 0.5fr 2.7fr 2fr 0.7fr 0.7fr;
          grid-gap: 24px;
          align-items: center;
          span {
            text-align: left;
            word-break: break-all;
          }
          @include for-phone-and-tablet {
            background: $light-color;
            margin-bottom: 4px;
            border-radius: 4px;
            border: 1px solid $card-border;
            grid-template-columns: 1fr;
            .amount,
            .status {
              display: none;
            }
            div {
              display: grid;
              grid-template-rows: 1fr;
              grid-template-columns: 0.5fr 1fr;
            }
          }
          .mobile-view {
            align-self: center;
            display: none;
            color: $subtitle-color;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 12px;
            @include for-phone-and-tablet {
              display: block;
              &.amount-block {
                display: flex;
                order: -1;
                justify-content: space-between;
                color: $dark-color;
              }
              .amount,
              .status {
                display: block;
              }
            }
          }
          .payment-method {
            display: flex;
            text-transform: none;
            img {
              margin-right: 10px;
            }
          }
          .amount {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
          .profit {
            color: #219653;
          }
          .img {
            text-align: right;
            cursor: pointer;
          }
          .bold {
            font-weight: 600;
          }
          .gray {
            color: $subtitle-color;
          }
          .status {
            text-transform: lowercase;
            text-align: center;
            font-size: 11px;
            line-height: 12px;
            letter-spacing: 0.4px;
            border-radius: 1px;
            padding: 2px 4px;
          }
          .pending {
            color: #e86d00;
            background: #fcede0;
          }
          .complete {
            color: #219653;
            background: #e4f2ea;
          }
          .closed {
            background: $card-border;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
    .designer-page {
      .table-wrapper {
        ul {
          .tableHeader {
            grid-template-columns: 0.8fr 0.5fr 2fr 1.5fr 0.8fr;
          }
          .table-item {
            grid-template-columns: 0.8fr 0.5fr 2fr 1.5fr 0.8fr;
          }
        }
      }
    }
  }
  .transactionsWrapper-designer {
    .table-wrapper {
      ul {
        .tableHeader, .table-item {
          grid-template-columns: 1.3fr 1.2fr 2fr 2.7fr 2fr 0.7fr;
        }
      }
    }
  }
  .transactionsWrapper-admin {
    .table-wrapper {
        ul {
          .tableHeader {
            grid-template-columns: 0.8fr 1.3fr 0.5fr 1fr 1.8fr 0.8fr 0.7fr 0.7fr 0.7fr 0.5fr;
            @include admin-table-only {
            display: none;
          }
          }
          .table-item {
            .mobile-view {
              @include admin-table-only{
                display: block;
              }
              .amount {
                @include admin-table-only{
                display: block;
              }
              }
            }
            grid-template-columns: 0.8fr 1.3fr 0.5fr 1fr 1.8fr 0.8fr 0.7fr 0.7fr 0.7fr 0.5fr;
            .btnWrapper {
              button {
                min-width: 25px;
                height: 40px;
                width: 100px;
                font-size: 12px;
                padding: 5px;
              }
            }
            @include admin-table-only{
            background: $light-color;
            margin-bottom: 4px;
            border-radius: 4px;
            border: 1px solid $card-border;
            grid-template-columns: 1fr;
            .amount,
            .status {
              display: none;
            }
            div {
              display: grid;
              grid-template-rows: 1fr;
              grid-template-columns: 0.5fr 1fr;
            }
          }
          }
        }
      }
      
  }
  .pagination {
    margin-top: 32px;
    justify-content: flex-end;
  }
}
