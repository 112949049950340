.radio {
  background-color: $light-color;
  border: 1px solid $dark-color;
  border-right: none;
  cursor: pointer;
  // height: 40px;
  padding: 14px 20px;
  text-align: center;
  outline: none;
  @include for-phone {
    border-right: 1px solid $dark-color;
    border-bottom: none;
  }
  &.active {
    background-color: $dark-color;
    color: $light-color;
  }
  &:first-child {
    border-bottom-left-radius: $border-radius;
    border-top-left-radius: $border-radius;
    @include for-phone {
      border-bottom-left-radius: 0px;
      border-top-right-radius: $border-radius;
    }
  }
  &:last-child {
    border-bottom-right-radius: $border-radius;
    border-right: 1px solid $dark-color;
    border-top-right-radius: $border-radius;
    @include for-phone {
      border-bottom: 1px solid $dark-color;
      border-bottom-left-radius: $border-radius;
      border-top-right-radius: 0px;
    }
  }
}
