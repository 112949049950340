@import './shared-variables.scss';

.inputWrapper {
  position: relative;
  width: 100%;
  color: $gray-color;
  .label {
    color: inherit;
    background: $light-color;
    position: absolute;
    height: 12px;
    left: 16px;
    top: -6px;
    padding: 0 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
  }
  .measurement-unit {
    position: absolute;
    right: 10px;
    top: 14px;
    padding: 0 6px;
    font-weight: 600;
    color: black;
    font-size: 16px;
  }
  input {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    width: 100%;
    border: 1px solid $light-gray;
    box-sizing: border-box;
    border-radius: $border-radius;
    padding: 14px 16px;
    font-size: 14px;
    line-height: 20px;
    background: $light-color;
    &::placeholder {
      color: $light-gray;
    }
    &:focus {
      outline: none;
      border-color: $dark-color;
      color: $dark-color;
      & + .label {
        color: $dark-color;
      }
    }
    &.inputError {
      border-color: #eb5757;
      & + .label {
        color: inherit;
      }
    }
  }
  .helperMessage {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    width: 100%;
    position: absolute;
    left: 16px;
    top: 56px;
  }
  &.inputError {
    color: #eb5757;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
  }
  .inputHasInfo {
    position: absolute;
    top: 15px;
    right: 8px;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}
