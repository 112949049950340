@import './shared-variables.scss';

.drop-down-container {
  position: absolute;
  z-index: 10;
  top: 32px;
  right: -23px;
  .drop-down {
    margin-bottom: 0;

    p {
      margin: 0 !important;
    }
    .wrapper.animatedEffect {
      width: 194px;
      height: 112px;
    }
    .fullInfoWrapper {
      padding: 20px 32px 20px 16px;
    }
    .profile,
    .log-out {
      display: flex;
    }
    .profile {
      margin-bottom: 24px;
      svg {
        width: 20%;
        margin-right: 9px;
      }
      p {
        width: 50%;
      }
    }
    .log-out {
      cursor: pointer;
      svg {
        margin-right: 14px;
        path {
          fill: $error-color;
        }
      }
      p {
        color: $error-color;
      }
    }
  }
  .triangle {
    top: -14px;
    transform: rotate(270deg);
    right: 23px;
  }
}
@media screen and (max-width: 768px) {
  .drop-down-container {
    right: -10px;
    .triangle {
      right: 10px;
    }
  }
}
