@import './shared-variables.scss';
.offer-wrapper {
  width: 268px;
  padding: 24px;
  background-color: $light-color;
  border-radius: $border-radius;
  border: 1px solid $card-border;
  .offer-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 21px;
    align-items: center;
    .left-side {
      font-size: 11px;
      line-height: 12px;
      color: $light-color;
      background-color: $dark-color;
      padding: 2px 8px;
      border-radius: 2px;
    }

    .left-side.true {
      background-color: $subtitle-color;
    }
    .right-side {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .offer-main {
    width: 220px;
    height: 220px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .offer-main.true {
    border: 1px solid $background-color;
    border-radius: 4px;
  }
  .offer-footer {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    .star-rating {
      margin-right: 32px;
    }
    .star-rating__star {
      margin-right: 6px;
    }

    .set-winner-title {
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: $light-color;
      background-color: $dark-color;
      margin-right: 8px;
      padding: 4px 14px;
      border-radius: 4px;
      cursor: pointer;
    }
    .winner-title {
      svg {
        margin-left: 10px;
      }
    }
  }

  .designer {
    .star-rating {
      margin-right: 0;
    }
  }

  .winner {
    display: inline-block;
    padding: 4px;
    background-color: $dark-color;
    border-radius: $border-radius;
    cursor: pointer;
  }
  img {
    cursor: default;
  }
  a img {
    cursor: pointer;
  }
}

.new {
  background: linear-gradient(
      0deg,
      rgba(33, 150, 83, 0.12),
      rgba(33, 150, 83, 0.12)
    ),
    #ffffff;
  .offer-header {
    .left-side {
      background-color: #219653;
      color: $light-color;
    }
  }
}

.has-winner {
  .offer-footer {
    .star-rating {
      margin-right: 0;
    }
  }
}

.is-winner-true {
  background-color: $dark-color;
  color: $light-color;
  .offer-header {
    .left-side {
      color: $dark-color;
      background-color: $light-color;
    }
  }
}
