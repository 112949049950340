@import './shared-variables.scss';
.detailed-info-wrapper {
  // margin: 32px 56px auto;
  // padding-bottom: 56px + $basicFooter;
  .search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    .title {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: $subtitle-color;
    }
    .element {
      font-size: 14px;
      line-height: 20px;
      color: $dark-color;
      margin-left: 20px;
    }
    .active {
      font-weight: 600;
    }
    &-left-side {
      .element {
        margin-left: 12px;
      }
    }
  }
  .offers {
    display: flex;
    flex-wrap: wrap;
    .offer-wrapper {
      margin-right: 32px;
      margin-bottom: 32px;
    }
  }
  .pagination {
    align-items: center;
    justify-content: flex-end;
  }
}
