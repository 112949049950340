.additional-info-project-wrapper {
  font-family: 'Source Sans Pro';
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  color: $dark-color;
  padding: 64px 0;
  .sub-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: #999999;
    margin-top: 12px;
    text-align: left;
    width: 90%;
    text-align: center;
  }
  .main-title {
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
  }
  .project-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-top: 16px;
  }
  .additional-info-project-form {
    width: 368px;
    margin-top: 44px;
    .small-inputs-additional {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0;
      .inputWrapper {
        width: 47%;
      }
    }
    .small-inputs-additional-three {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0;
      .inputWrapper {
        width: 32%;
      }
    }
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: $dark-color;
      width: 100%;
      text-align: left;
      margin-top: 32px;
    }
    .sub-title {
      color: $dark-color;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: $subtitle-color;
      margin-top: 12px;
      text-align: left;
    }
    .file-wrapper {
      margin: 16px 0 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 58px;
      #loading_doc {
        opacity: 0;
        position: absolute;
        z-index: -1;
        top: -9999px;
        left: -9999px;
      }
      label {
        background: transparent;
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: $dark-color;
        letter-spacing: 1px;
        span {
          border-bottom: 1px solid $dark-color;
          margin-right: 12px;
        }
      }
    }
    .file-list {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      .item-file {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $dark-color;
      }
      & div:nth-child(2n) {
        background: $background-color;
      }
      & div:nth-child(odd) {
        background: $card-border;
      }
    }
  }
  .btnWrapper {
    margin-top: 0;
    button {
      width: inherit;
    }
    #additional-button {
      color: $dark-color;
      background: $light-color;
      border: 1px solid $dark-color;
      width: 368px;
      margin-top: 32px;
    }
  }
}
