@import './shared-variables.scss';

.tariffWrapper {
  background: $light-color;
  border: 1px solid $card-border;
  box-sizing: border-box;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  padding: 36px 0 24px;
  // width: 230px;
  width: auto;
  position: relative;
  .item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    border-top: 1px solid $card-border;
    &.desc {
      // height: 190px;
      justify-content: center;
    }
    &.planType{
      height: 100px;
      justify-content: center;
    }
    &.top {
      font-style: normal;
      border: none;
      display: flex;
      align-items: center;
      text-align: center;
      color: $dark-color;
      padding: 0;
      margin-bottom: 24px;
      .amount {
        font-weight: bold;
        font-size: 28px;
        line-height: 38px;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        text-align: center;
      }
      .tax {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }
    }
    .item-title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: $dark-color;
      align-self: center;
      text-align: center;
    }
    .item-advantageTitle{
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.5px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: $dark-color;
      align-self: center;
      text-align: center;
    }
    .item-description {
      font-style: normal;
      font-size: 13px;
      line-height: 17px;
      letter-spacing: 0.5px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: $dark-color;
      align-self: center;
      text-align: center;
      justify-content: center;
    }
    .item-advantage {
      text-align: left;
      display: flex;
      margin-top: 16px;
      font-size: 14px;
      line-height: 20px;
      img {
        margin-right: 6px;
      }
      .false {
        color: $subtitle-color;
      }
    }
    .item-container {
      // min-height: 530px;
      width: 100%;
      min-height: 330px;
      .subtitle{
        height: 70px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
    }
    .btnWrapper {
      width: 100%;
      margin-top: 24px;
      .button.btnDisabled {
        cursor: pointer;
        color: $dark-color;
        border: 1px solid $dark-color;
        background-color: $light-color;
        &:hover {
          color: $light-color;
          background-color: $dark-color;
          border: none;
          transition: none;
        }
      }
      .btnActive {
        cursor: default;
        border: 1px solid $dark-color;
        &:hover {
          color: $light-color;
          background-color: $dark-color;
          transition: none;
        }
      }
    }
  }
  &.checked {
    border: 1px solid $dark-color;
  }
  .checked {
    position: absolute;
    top: -15px;
    left: calc(50% - 16px);
  }
}

.tariffs {
  justify-content: center;
  .summary {
    width: 22%;
    font-size: 14px;
    position: fixed;
    top: 170px;
    right: 20px;
    background: #ffffff;
    padding: 15px;
    border: 1px solid #efefef;
    transition: opacity 0.5s 1s;
    .info-block {
      .summary-header {
        text-align: left;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
        width: 100%;
        overflow: hidden;
      }
      .block-header {
        color: $subtitle-color;
        font-size: 11px;
        margin-bottom: 24px!important;
      }
      .info-row-item{
        display: flex;
        align-items: center;
      }
      .info-block-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        flex-wrap: wrap;
        .block-child {
          text-align: left;
          padding-left: 10px;
          width: 100%;
          font-size: 12px;
          div {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
    &.show {
      display: block!important;
      box-shadow: -3px 3px 6px #00000021;
    }
  }
}
.info-block-item {
  text-align: left;
}
.close-summary {
  display: none;
  float: right;
}

.summary-mobile {
  display: none;
  position: fixed;
  top: 170px;
  right: 20px;
  z-index: 1;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 4%);
    border: 1px solid #f0f0f0;
    width: 48px;
    height: 48px;
    background-color: #ffffff;
    img {
      width: 32px;
    }
  }
}

.EFH-Garten {
  .top {
    .item-title {
      width: 108px;
    }
  }
}
.Hinterhofgarten {
  .top {
    .item-title {
      width: 196px;
    }
  }
}
.Parkähnlicher {
  .top {
    .item-title {
      width: 174px;
    }
  }
}
.upsellContainer {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
}
// .Basis {
//   order: 0;
// }

// .Minimum {
//   order: 2;
// }

// .Premium {
//   order: 1;
// }
@media (max-width: 1000px) {
  .close-summary {
    display: block;
  }
  .summary {
    width: 300px !important;
    top: 170px !important;
    right: 20px !important;
    z-index: 2;
  }
  .tariffs .summary {
    display: none;
  }
  .summary-mobile {
    display: block;
  }
}

@media only screen and (max-width: 769px) {

  .create-project .tariffs .tariffWrapper {
    margin-right: 0!important;
  }
  .tariffs {
    padding-top: 15px;
  }
  .summary {
    right: 0px !important;
  }
  .summary-mobile {
    right: 0px;
  }
}

@media only screen and (max-width: 390px) {
  .tariffs .summary {
    width: 220px !important;
    display: none;
  }
}