@import './shared-variables.scss';
.faqWrapper {
  display: grid;
  
  font-family: sans-serif;
  grid-template-columns: 325px auto;
  padding-bottom: $basicFooter;
  text-align: left;
  padding-right: 56px;
  em {
    font: unset;
  }
  p {
    margin-bottom: 32px;
  }
  .navigation-container,
  .questions-container {
    padding-top: 56px;
    padding-bottom: 48px;
  }
  .navigation-container {
    .nav-list {
      .btnWrapper {
        width: 236px;
        margin-top: 65px;
        button {
          height: 100%;
          text-align: center;
        }
      }
    }
  }
  .questions-container {
    padding-left: 30px;
    h1 {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.8px;
      margin-bottom: 40px;
    }
    .search input {
      padding-left: 40px;
    }
    .search::placeholder {
      font-size: 14px;
      line-height: 20px;
      color: $dark-color;
    }
    svg {
      top: 16px;
      left: 16px;
      position: absolute;
    }
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 52px 0 20px 0;
    .questions-container {
      padding: 0 16px;
      width: 100%;
      .inputWrapper {
        margin-bottom: 16px;
      }
    }
  }
}
.tipps-content {
  p {
    margin-bottom: 32px;
    em {
      letter-spacing: 0.4px;
      line-height: 24px;
    }
  }
}
.faq {
  font: unset;
  .item-title {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.5px;
    line-height: 28px;
    margin: 56px 0 32px 0;
    &.uppercase {
      text-transform: uppercase;
    }
  }
  .default-text {
    margin-bottom: 32px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }
  .question-container {
    display: flex;
    flex-direction: column;
    background: $light-color;
    padding: 24px;
    margin-bottom: 4px;
    border: 1px solid $card-border;
    border-radius: $border-radius;
    &.opened,
    &:hover {
      border-color: $dark-color;
    }
    .question {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: bold;
        .no {
          min-width: 24px;
          height: 24px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $dark-color;
          border-radius: $border-radius;
          color: $light-color;
          font-weight: bold;
        }
        .text {
          margin: 0 28px;
          white-space: normal;
          line-height: 24px;
          @media (max-width: 320px) {
            margin: 0 16px;
            line-height: 20px;
            width: 70%;
          }
        }
      }
    }
    .answer {
      line-height: 24px;
      padding: 16px 47px 0 52px;
      ol,
      ul {
        margin: auto;
        padding: inherit;
      }
      ol {
        list-style-type: decimal;
      }
      ul {
        list-style-type: disc;
      }
    }
    .answer:first-child {
      white-space: initial;
    }
  }
}
