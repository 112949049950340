@import './shared-variables.scss';

.snackbar {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  visibility: hidden;
  padding: 12px 16px;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: $border-radius;
  color: $light-color;
  max-width: 40%;
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 20px;
  &.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 5.0s;
    animation: fadein 0.5s, fadeout 0.5s 5.0s;
    &.info {
      border-left: 4px solid $info-color;
    }
    &.success {
      border-left: 4px solid $success-color;
    }
    &.warning {
      border-left: 4px solid $warning-color;
    }
    &.error {
      border-left: 4px solid $error-color;
    }
  }
  .title {
    white-space: nowrap;
    font-weight: bold;
  }
  .message {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 10px;
  }
}

@-webkit-keyframes fadein {
  from {right: 0; opacity: 0;} 
  to {right: 20px; opacity: 1;}
}

@keyframes fadein {
  from {right: 0; opacity: 0;}
  to {right: 20px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {right: 20px; opacity: 1;} 
  to {right: 0; opacity: 0;}
}

@keyframes fadeout {
  from {right: 20px; opacity: 1;}
  to {right: 0; opacity: 0;}
}