@import "./shared-variables.scss";

.pagination {
  align-items: center;
  li {
    border: 1px solid $dark-color;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: 12px;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
    a {
      text-decoration: none;
      border-bottom: none;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      font-feature-settings: "pnum" on, "lnum" on;
      color: $dark-color;
      outline: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
       padding: 8px;
    }
  }
  .active {
    background-color: $dark-color;
    a {
      color: $light-color;
    }
  }
  .previous,
  .next,
  .break {
    border: none;
  }
}
img {
  margin: 0;

  cursor: pointer;
}

.small-pagination {
  .pagination {
    li {
      border: 1px solid $dark-color;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 3px 10px;
      margin-left: 12px;
      cursor: pointer;
      &:first-child {
        margin-left: 0;
      }
      a {
        text-decoration: none;
        border-bottom: none;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: $dark-color;
      }
    }
    .active {
      background-color: $dark-color;
      a {
        color: $light-color;
      }
    }
  }
}
