@import './shared-variables.scss';
.briefing {
  display: flex;
  margin: 0px 56px auto;
  padding-bottom: $basicFooter;
  .left-side {
    border-right: 1px solid #f0f0f0;
    text-align: left;

    font-size: 16px;
    line-height: 24px;
    width: 23%;
    .nav-bar {
      padding-top: 32px;
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      padding-bottom: 32px;
    }

    .link {
      margin-bottom: 32px;
      padding-right: 32px;
    }

    .active {
      position: relative;
      font-weight: 600;
      line-height: 24px;
      border-right: 1px solid black;
    }
    .Dachtyp,
    .Sie {
      border-right: none;
      font-weight: normal;
    }

    span {
      color: $dark-color;
    }
    span:hover {
      cursor: pointer;
    }
  }
  .right-side {
    margin-left: 32px;
    text-align: left;
    width: 77%;
    img {
      cursor: auto;
    }
    h2 {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $dark-color;
    }
    .table-wrapper {
      margin-top: 32px;
      ul {
        .table-item {
          box-sizing: border-box;
          color: $dark-color;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          padding: 16px 24px;
          display: grid;
          grid-template-rows: 1fr;
          grid-template-columns: 1fr 1fr;
          grid-gap: 24px;
          align-items: center;
          span {
            text-align: left;
          }
          span:nth-child(2n) {
            text-align: right;
            text-transform: none;
          }
        }
        .table-item:nth-child(odd) {
          background: $light-color;
        }
        .table-item-details {
          box-sizing: border-box;
          color: $dark-color;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          padding: 16px 24px;
          align-items: center;
          .item-listing {
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 24px;
            align-items: center;
            span:not(:first-child) {
              text-align: right;
            }
          }
          .item-listing:not(:last-child){
            padding-bottom: 15px;
          }
        }
        .table-item-details:nth-child(odd) {
          background: $light-color;
        }
      }
    }

    .work {
      padding-top: 32px;
      padding-bottom: 28px;
      .card-wrapper {
        margin-top: 32px;
        width: 168px;
        background: transparent;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        .img-container {
          border-radius: 5px;
          background: $light-color;
          border-radius: 4px;
          width: 168px;
          height: 168px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: 1px solid $card-border;
          text-align: center;
          img {
            border-radius: 5px;
            height: auto;
            &.PRODUKT {
              height: auto;
              width: 100%;
            }
          }
          p {
            &.PRODUKT {
              margin-top: 10px;
              padding-bottom: 25px;
            }
          }
        }
        p {
          margin-top: 22px;
          font-weight: 600;
        }
      }
    }

    .style {
      padding-top: 28px;
      padding-bottom: 28px;
      h2 {
        margin-bottom: 32px;
      }
      .cards {
        display: flex;
        text-align: center;
        flex-wrap: wrap;
        .card-wrapper {
          margin-right: 32px;
          margin-bottom: 32px;
          width: 170px;
        }
        p {
          margin-top: 22px;
          font-weight: 600;
        }
      }
    }

    .questions-about-the-room-program {
      padding-top: 28px;
      padding-bottom: 28px;
      h2 {
        margin-bottom: 32px;
      }
      .no {
        margin-right: -5px;
      }
    }

    .options {
      display: flex;
      padding-top: 28px;
      padding-bottom: 28px;
      .space-style {
        margin-right: 107px;
        width: 45%;
        span:nth-child(2n) {
          font-weight: 600;
          letter-spacing: 0.4px;
        }
      }
      .additional-options {
        width: 45%;
        ul {
          .table-item {
            grid-template-columns: 0.1fr 1fr;
            grid-gap: 0px;
            span {
              text-align: left;
            }
          }
        }
      }
      .no {
        margin-left: -5px;
      }
    }

    .construction-roof-container {
      display: flex;
      padding-top: 28px;
      padding-bottom: 28px;
      h2 {
        margin-bottom: 32px;
      }
      .construction {
        margin-right: 70px;
      }
      p {
        margin-top: 32px;
        text-align: center;
        line-height: 24px;
      }
    }
    .addRequirement{
      font-size: 12px;
      cursor: pointer;
    }
    .requirements {
      padding-top: 10px;
      padding-bottom: 10px;
      .date{
        color: $subtitle-color;
        font-size: 12px;
        line-height: 18px;
      }
      h2 {
        margin-bottom: 32px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.4px;
      }
      
    }

    .further-information {
      padding-top: 28px;
      padding-bottom: 28px;
      .btnWrapper {
        margin-top: 32px;
        width: 281px;
      }
      .list-data {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        max-width: 468px;
        .post-item {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          padding: 16px 24px;
          display: flex;
          justify-content: space-between;
          span {
            font-weight: 600;
            text-transform: uppercase;
          }
        }
        .item-file {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 14px 16px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $dark-color;
        }
        & div:nth-child(2n) {
          background: $background-color;
        }
        & div:nth-child(odd) {
          background: $light-color;
        }
      }
      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 32px 0 32px;
      }
    }

    .data {
      padding-top: 28px;
      margin-bottom: 440px;
      .info {
        width: 566px;
        flex-wrap: wrap;
        background: $light-color;
        padding: 40px;
        display: flex;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.4px;
        border: 1px solid $card-border;
        border-radius: 4px;
        margin-top: 32px;
        div {
          width: 50%;
          margin-bottom: 12px;
        }
        svg {
          margin-right: 13px;
        }
        svg.no {
          margin-right: 5px;
          margin-left: -5px;
        }
      }
    }
  }
  .img-container {
    background: $light-color;
    border-radius: 4px;
    width: 168px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $card-border;
    text-align: center;
    img {
      -webkit-width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .briefing {
    .right-side {
      .data {
        .info {
          padding: 40px 20px;
          width: auto;
        }
      }
    }
  }
}
@media screen and (max-width: 860px) {
  .briefing {
    .right-side {
      .options {
        flex-direction: column;
        .space-style {
          margin-right: 0;
          width: 100%;
        }
        .additional-options {
          width: 100%;
          margin-top: 48px;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .briefing {
    margin: 0px auto;
    padding-bottom: 40px;
    .left-side {
      display: none;
    }
    .right-side {
      margin-left: 0;
      padding: 0 16px;
      width: auto;
      .style {
        .cards {
          display: flex;
          justify-content: space-around;
          .card-wrapper {
            margin-right: 12px;
            width: 140px;
            p {
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              margin-top: 16px;
            }
            .img-container {
              height: 112px;   
              img {
                height: 100%;
              }
            }
          }
        }
      }
      .questions-about-the-room-program {
        .table-wrapper {
          ul {
            .table-item {
              padding: 12px 16px;
              grid-gap: 0;
              grid-template-columns: 1.5fr 0.5fr;
            }
          }
        }
      }
      .construction-roof-container {
        flex-direction: column;
        .construction,
        .roof-type {
          margin-top: 48px;
          .card-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
      .data {
        width: 100%;
        margin-bottom: 0;
        .info {
          margin-top: 24px;
          flex-direction: column;
          padding: 16px;
          div {
            width: 100%;
          }
        }
      }
    }
  }
}
