@import '../shared-variables.scss';
.personal-info {
  &-inputs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include for-phone {
      flex-direction: column;
    }
    input,
    .label {
      background: $background-color;
    }
    .inputWrapper:first-child {
      width: 61%;
      @include for-phone {
        width: 100%;
      }
    }
    .inputWrapper:last-child {
      width: 35%;
      @include for-phone {
        margin-top: 34px;
        width: 100%;
      }
    }
  }
  .helperMessage p {
    text-align: left;
  }
}
.roof-type {
  .cardWrapper {
    margin-bottom: 32px;
    .inner-title {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
.selected-double-range {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  width: 100%;
  &-dash {
    margin: 0 10px;
  }
}
.selectable-blocks {
  &-checkboxes {
    width: 60%;
    * {
      width: 50%;
    }
  }
  .radio {
    padding: 7px 20px;
    outline: none;
  }
}
.textarea-for-specifications {
  border: 1px solid $light-gray;
  border-radius: $border-radius;
  resize: none;
  width: 100%;
  height: 120px;
  padding: 14px 16px;
  &::placeholder {
    color: $subtitle-color;
    font-size: 14px;
    line-height: 20px;
  }
}
.living-space {
  .selectable-blocks {
    @include for-phone {
      .two-items {
        flex-direction: column;
        align-items: flex-end;
        padding: 16px;
        .title {
          float: left;
          width: 100%;
        }
      }

      .three-items {
        flex-direction: column;
        .title {
          margin-bottom: 20px;
        }
      }
      .children {
        margin-left: 0;
      }
      .radio {
        border-bottom: 1px solid $dark-color;
        border-right: none;
        padding: 7px 12px;
        &:first-child {
          border-bottom-left-radius: $border-radius;
          border-top-right-radius: 0;
        }
        &:last-child {
          border-bottom-left-radius: 0;
          border-right: 1px solid $dark-color;
          border-top-right-radius: $border-radius;
        }
      }
    }
  }
}
