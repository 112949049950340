@import './shared-variables.scss';
.page-wrapper {
  padding-bottom: 56px + $basicFooter;
  @media (max-width: 768px) {
    padding-bottom: $mobileFooter;
  }
  .title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .user-name {
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.8px;
      color: $dark-color;
      margin: 0 8px;
    }
    .account-role {
      background: $light-gray;
      border-radius: 1px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $dark-color;
      padding: 2px 6px;
    }
    .logout {
      cursor: pointer;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-left: 68px;
      color: $error-color;
      svg {
        margin-right: 14px;
        path {
          fill: $error-color;
        }
      }
    }
  }
  .profile-wrapper {
    margin-top: 56px;
    width: 100%;
    display: flex;
    justify-content: center;
    div {
      width: 368px;
      text-align: left;
      .main-title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $dark-color;
      }
      .sub-title {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $subtitle-color;
        margin-top: 12px;
      }
      .accauntForm {
        width: 100%;
        .inputWrapper {
          margin-top: 32px;
        }
        .small-inputs {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .inputWrapper {
            width: 47%;
          }
        }
      }
      &:first-child {
        margin-right: 32px;
      }
      .btn-width {
        margin-top: 32px;
        width: fit-content;
        display: flex;
        .btnWrapper {
          width: auto;
        }
      }
      .password-wrapper {
        margin-top: 32px;
        overflow: hidden;
        animation-duration: 1.5s;
        animation-name: slideinPass;
        .position {
          .change-pass-form {
            width: 100%;
            .inputWrapper {
              margin-top: 32px;
            }
          }
          .close-change-pass {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            width: auto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $subtitle-color;
            span {
              margin-left: 10px;
            }
          }
        }
      }
    }
    .label,
    input {
      background: $background-color;
    }
  }
}

@-webkit-keyframes slideinPass {
  from {
    height: 0%;
  }

  to {
    height: 100%;
  }
}
@keyframes slideinPass {
  from {
    height: 0%;
  }

  to {
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .page-wrapper {
    padding-bottom: 48px;
    .title {
      .logout {
        margin-left: 20px;
      }
      .user-name {
        font-size: 28px;
      }
    }
    .profile-wrapper {
      display: block;
      div {
        // width: 330px;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 387px) {
  .page-wrapper {
    padding-bottom: 48px;
    .title {
      align-items: start;
      flex-wrap: wrap;
      .user-name {
        width: 100%;
        font-size: 28px;
      }
      > img {
        display: none;
      }
    }
    .profile-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      div {
        width: 100%;
        .main-title {
          margin-top: 32px;
        }
        .btn-width {
          flex-direction: column;
          align-items: start;
          justify-content: space-between;
          height: 100px;
        }
      }
    }
  }
}
