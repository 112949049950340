@import './shared-variables.scss';
.list-item-container {
  width: 100%;
  margin: 0 auto;
  background-color: $light-color;
  padding: 24px;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: left;
  margin-bottom: 4px;
  border: 1px solid $card-border;
  border-radius: $border-radius;
  display: flex;
  .title {
    &.with-info {
      .tooltip-block{
        float: right;
        padding-left: 8px;
        &:hover{
          position: relative;
          top: 0px;
          .tooltip-arrow {
            top: 3px;
          }
          .tooltip-text {
            top: 0px;
          }
        }
      }
    }
  }
}

.list-item-container.block {
  display: block;
}

.two-items {
  align-items: center;
  justify-content: space-between;
  word-break: break-all;
  .children {
    margin-left: 24px;
  }
}

.two-items.children-flex {
  align-items: center;
  justify-content: space-between;
  word-break: break-all;
  .children {
    margin-left: 24px;
    display: flex;
    align-items: center;
    .label-right {
      width: 15%;
      text-align: right;
    }
  }
}

.two-items-left {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .children {
    margin-right: 50px;
    letter-spacing: 0.5px;
    span {
      font-weight: 600;
    }
  }
}

.three-items {
  flex-direction: row;
  .tooltip-block {
    position: relative;
    &:hover {
      opacity: 1;
    }
  }
  .tooltip-text {
    top: -3px;
  }
  .tooltip-arrow {
    top: 0px;
  }
  @include for-phone-and-tablet {
    flex-direction: column;
  }
  .title {
    font-weight: 600;
    // margin-right: 44px;
    margin-right: 21px;
    letter-spacing: 0.5px;
    @include for-phone-and-tablet {
      margin-bottom: 10px;
    }
  }
  .checkBoxWrapper {
    @include for-phone-and-tablet {
      margin-top: 10px;
    }
  }
  .checkBoxWrapper:first-child {
    // margin-right: 44px;
    margin-right: 21px;
  }
  .children {
    display: flex;
    @include for-phone-and-tablet {
      flex-wrap: wrap;
    }
  }
}

.three-items.with-counter {
  justify-content: space-between;
  align-items: center;
  .title {
    font-weight: 500;
  }
  .inputWrapper {
    padding-right: 50px;
  }
  .countWrapper {
    width: 132px;
  }
}

.three-items.with-counter.no-border {
  border: 0;
  padding: 0;
}

.with-slider {
  flex-direction: column;
  .children {
    margin-top: 20px;
  }
}
