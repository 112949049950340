@import './shared-variables.scss';
.btnFooter {
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .btnWrapper {
    margin: 0 10px;
  }
}
.basicFooter {
  background: rgba(0, 0, 0, 0.04);
  height: $basicFooter;
  padding: 0 56px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  
  a {
    color: black
  }
  .column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 77%;
    &.left {
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      .social-links-container {
        a {
          margin-right: 28px;
        }
      }
      .contactTitle {
        font-weight: 600;
        letter-spacing: 0.5px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $dark-color;
      }
      .contactDetails {
        font-weight: normal;
        letter-spacing: 0.4px;
        color: $gray-color;
      }
      .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .btnFooter {
        width: max-content;
      }
    }
    &.right {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: $gray-color;
      .paymentSystem {
        margin-top: 20px;
        .paymentImg {
          margin-top: 30px;
          display: flex;
          img {
            margin: 0 24px;
          }
        }
      }
      .item {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      .date {
        padding-top: 56px;
      }
    }
  }
  .center {
    letter-spacing: 0.5px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: initial;
    height: 64%;
    margin: 0 16px;
    width: 26%;
    .nav {
      display: grid;
      grid-template-columns: 50% 50%;
      .item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        ul {
          margin: 32px 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          a {
            margin-bottom: 20px;
            text-align: left;
            color: $dark-color;
          }
          a:hover {
            text-decoration: none;
          }
        }
      }
    }
    .links {
      display: grid;
      grid-template-columns: 50% 50%;
      text-align: left;
      a {
        display: flex;
        align-items: center;
        height: 52px;
        .footerLink {
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $dark-color;
          border-bottom: 1px solid $dark-color;
          white-space: nowrap;
          line-height: 24px;
          margin-bottom: 10px;
        }
        .footerLink:hover {
          border-bottom: none;
        }
      }
      a:hover {
        text-decoration: none;
      }
    }
  }
  @media (max-width: 1000px) {
    position: relative;
    padding: 0 18px 0;
    .center {
      .item {
        .footerLink {
          text-align: left;
          letter-spacing: 0.1px;
          font-size: 14px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    height: $mobileFooter;
    padding: 48px 16px;
    align-items: flex-start;
    width: 100%;
    .center {
      width: 100%;
      margin: 0;
      .nav {
        .item ul {
          margin: 0;
        }
      }
      .links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .column {
      height: auto;
      &.left {
        margin-bottom: 40px;
        .contactDetails {
          margin: 24px 0;
        }
      }
      &.right {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        .paymentSystem {
          margin: 0;
          .paymentImg {
            margin: 0;
            img {
              margin: 0;
            }
            img:nth-child(2) {
              margin: 0 30px;
            }
          }
        }
        .date {
          padding-top: 0;
        }
      }
    }
  }
}

.projectFooter {
  font-family: 'Source Sans Pro';
  background: $light-color;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.04);
  height: $projectFooter;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 56px;
  letter-spacing: 0.5px;
  margin-top: 56px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  position: absolute;
  bottom: 0;
  width: 100%;
  .item {
    display: flex;
    align-items: center;
    cursor: pointer;
    &.left {
      color: $gray-color;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
  .btnFooter {
    width: max-content;
  }
  @media (max-width: 768px) {
    padding: 0 16px;
    overflow-x: auto;
  }
  @media (max-width: 420px) {
    .item {
        &.left img,
        img {
          margin-right: 0;
        }
        span {
          display: none;
        }
    }
    .btnFooter {
      .btnWrapper {
        button {
          min-width: 120px;
        }
      }
    }
  }
  /*media for iphone 5/SE landscape*/
  @media (min-width: 550px) and (max-width: 570px) {
    .btnFooter {
      .btnWrapper {
        button {
          min-width: 120px;
        }
      }
    }
  }
  @media (max-width: 320px) {
    .btnFooter {
      .btnWrapper {
        button {
          min-width: 100px;
        }
      }
    }
  }
}
