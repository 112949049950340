@import './shared-variables.scss';

.create-project {
  .first-step,
  .second-step,
  .third-step,
  .fourth-step {
    padding-top: 56px;
    padding-bottom: 56px + $projectFooter;
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
  }
  .third-step {
    width: 50%;
  }
  @media only screen and (max-width: 1000px) {
    .third-step {
      width: 85%;
    }
  }
  .second-step {
    .further-specifications {
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $dark-color;
        width: 100%;
        text-align: left;
        margin-top: 32px;
      }
      .sub-title {
        color: $dark-color;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: $subtitle-color;
        margin-top: 12px;
        text-align: left;
      }
      textarea {
        font-style: normal;
        font-weight: normal;
        width: 100%;
        border: 1px solid $light-gray;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 14px 16px;
        font-size: 14px;
        line-height: 20px;
        background: $light-color;
        height: 192px;
        resize: none;
        overflow: auto;
        &:focus,
        &:active {
          outline: 0;
          border: 1px solid $dark-color;
          border-radius: 4px;
        }
        &.error {
          border-color: #eb5757;
        }
        &::placeholder {
          color: $subtitle-color;
          font-size: 14px;
          line-height: 20px;
        }
      }
      .file-list {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        max-width: 368px;
        .item-file {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 14px 16px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $dark-color;
        }
        & div:nth-child(2n) {
          background: $background-color;
        }
        & div:nth-child(odd) {
          background: $card-border;
        }
      }
      .file-wrapper {
        margin: 16px 0 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 58px;
        #loading_file {
          opacity: 0;
          position: absolute;
          z-index: -1;
          top: -9999px;
          left: -9999px;
        }
        label {
          background: transparent;
          cursor: pointer;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: $dark-color;
          letter-spacing: 1px;
          span {
            border-bottom: 1px solid $dark-color;
            margin-right: 12px;
          }
        }
      }
    }
  }

  h2 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.8px;
  }
  .text p {
    margin-top: 8px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $subtitle-color;
  }
  .category {
    margin-top: 40px;
    margin-bottom: 40px;
    .radio {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      outline: none;
    }
    .radio-group {
      @include for-phone {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .type {
    margin-bottom: 72px;
    .radio-group {
      display: flex;
      justify-content: center;
      .cardWrapper {
        margin-right: 32px;
        @include for-phone {
          margin-right: 11px;
        }
      }
      .cardWrapper:last-child {
        margin-right: 0;
      }
    }
  }

  .style {
    margin: 0 auto;
    max-width: 768px;
    margin-top: 40px;
    .radio-group {
      display: flex;
      flex-wrap: wrap;
      @include for-phone {
        justify-content: space-around;
      }
      @include for-tablet {
        justify-content: space-around;
      }
    }
    .cardWrapper {
      margin-right: 32px;
      @include for-phone {
        margin-right: 4px;
        &:nth-child(even) {
          margin-right: 0;
        }
      }
      p {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .cardWrapper:nth-child(4n + 4) {
      margin-right: 0px;
    }
  }
  .days {
    margin-bottom: 56px;
    margin-top: 40px;
    .list-item-container:first-child {
      margin-bottom: 24px;
    }
  }

  .tariffs {
    display: flex;
    margin-top: 56px;
    margin-bottom: 56px;
    @include for-phone-and-tablet {
      overflow-y: auto;
      padding-bottom: 10px;
    }
    .tariffWrapper {
      // margin-right: 36px;
    }
    .tariffWrapper:last-child {
      margin-right: 0;
    }
  }

  .upsell {
    display: flex;
    margin-top: 56px;
    margin-bottom: 56px;
    @include for-phone-and-tablet {
      overflow-y: auto;
      padding-bottom: 10px;
    }
    .upsellWrapper {
      margin-right: 36px;
    }
    .upsellWrapper:last-child {
      margin-right: 0;
    }
  }

  @include for-phone {
    .third-step {
      .list-item-container.three-items {
        flex-direction: column;
        div:first-child {
          margin-bottom: 20px;
          margin-right: 0;
        }
        .title {
          margin-bottom: 20px;
        }
        .children {
          flex-direction: column;
        }
      }
    }
  }

  .rules {
    margin-top: 56px;
    margin-bottom: 64px;
    text-align: left;
  }
  @include for-phone-and-tablet {
    margin: 0 20px;
  }
}
