@import './shared-variables.scss';

.fixTriangle {
  position: relative;
  text-align: right;
  margin-bottom: 40px;
  z-index: 2;
  .wrapper {
    background: $light-color;
    width: 48px;
    height: 48px;
    border: 1px solid $card-border;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px $light-gray-color;
    border-radius: 4px;
    overflow: hidden;
    white-space: nowrap;
    transition: all 0.7s;
    display: inline-block;
    .preview {
      opacity: 1;
      transition: opacity 0.5s 2s;
      cursor: pointer;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .fullInfoWrapper {
      position: relative;
      padding: 24px;
      opacity: 0;
      transition: opacity 0.5s 1s;
      .close {
        position: absolute;
        top: 14px;
        right: 14px;
        cursor: pointer;
      }
      .fullInfo {
        width: 120px;
        .callUsWrapper,
        .rateWrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          text-align: left;
          .title {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.5px;
            color: $dark-color;
          }
          .subTitle {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: $dark-color;
            margin-top: 20px;
          }
          .phone {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.4px;
            color: $gray-color;
            margin: 20px auto;
          }
          .workTime,
          .remark {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: $gray-color;
            letter-spacing: 0.4px;
          }
          .rate {
            margin-top: 10px;
          }
        }
      }
    }
    &.animatedEffect {
      width: 168px;
      height: 256px;
      .preview {
        opacity: 0;
      }
      .fullInfoWrapper {
        opacity: 1;
      }
    }
  }
  .triangle {
    position: absolute;
    right: -9px;
    top: 12px;
    z-index: 1;
  }
}
