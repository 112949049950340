@import './shared-variables.scss';

.communication-container {
  display: grid;
  grid-template-columns: 275px auto;
  padding-bottom: $basicFooter;
}

.communication-container,
.faqWrapper {
  .chat-mobile-icon {
    display: none;
  }
  .navigation-container {
    border-right: 1px solid $card-border;
    margin-left: 56px;

    .list-label {
      margin-top: 64px;
      margin-bottom: 12px;
      text-align: start;
      font-size: 75%;
      color: $subtitle-color;
    }
    .nav-list {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    a {
      height: 44px;
    }
    h3 {
      line-height: 24px;
      &:nth-child(2) {
        margin: 32px 0;
      }
    }
    a,
    h3 {
      display: flex;
      align-items: center;
      color: $dark-color;
      &.selected {
        width: 100%;
        border-right: 1px solid $dark-color;
        font-weight: bold;
      }
      .nav-elem {
        display: flex;
        align-items: center;
        img {
          margin-right: 14px;
        }
        .unread-messages {
          padding: 5px;
          background-color: $dark-color;
          color: $light-color;
          font-size: 11px;
          margin: 0 5px;
        }
      }
    }
    a:hover {
      text-decoration: none;
    }
    h3:hover {
      cursor: pointer;
    }
    a:first-child {
      margin-top: 52px;
    }
  }
}

.communication-container,
.concept-chat-block {
  .message-container {
    padding-left: 32px;
    margin-right: 56px;
    margin-top: 52px;
    margin-bottom: 32px;
    .chat-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .chat-mobile-icon {
        display: none;
      }
      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 600;
        .refresh {
          text-transform: lowercase;
          padding: 2px;
          background-color: $dark-color;
          color: $light-color;
          font-size: 11px;
          margin: 0 5px;
          cursor: pointer;
        }
      }
      .description {
        font-size: 12px;
        color: $subtitle-color;
        text-align: right;
      }
    }
    .message-list {
      margin-top: 32px;
      height: 500px;
      overflow-y: auto;
      scroll-behavior: smooth;
      -webkit-mask-size: 100%;
      // -webkit-mask-image: -webkit-gradient(
      //   linear,
      //   center top,
      //   center bottom,
      //   color-stop(0, rgba(0, 0, 0, 0)),
      //   color-stop(0.2, rgba(0, 0, 0, 1))
      // );
      .sending-result {
        .success-message {
          .refresh-chat {
            font-weight: bold;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .no-comments {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        // vertical-align: middle;
      }
    }
    .date-list {
      margin-bottom: 50px;
    }
    .date-label {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      color: $subtitle-color;
    }
    .date-label:before,
    .date-label:after {
      content: '';
      border-top: 1px solid $card-border;
      margin: 0 10px 0 0;
      flex: 1 0 10px;
    }
    .date-label:after {
      margin: 0 0 0 10px;
    }
    .company-message,
    .my-message {
      margin-top: 32px;
    }
    .company-message {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .message-header {
        display: flex;
        align-items: center;
        margin-bottom: 22px;
        .name {
          margin: 0 12px;
          font-weight: bold;
          font-size: 14px;
        }
        .date {
          font-size: 12px;
          color: $subtitle-color;
        }
      }
      .message-text {
        padding: 16px;
        background: rgba(217, 217, 217, 0.2);
        border-radius: 0px 4px 4px 4px;
        max-width: 70%;
        text-align: start;
        line-height: 24px;
        position: relative;
      }
      .message-text:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0%;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-bottom-color: rgba(217, 217, 217, 0.2);
        border-top: 0;
        border-left: 0;
        margin-left: 0px;
        margin-top: -12px;
      }
    }
    .my-message {
      display: flex;
      justify-content: flex-end;
      .message-text {
        padding: 16px;
        background: $dark-color;
        border-radius: 4px 4px 0px 4px;
        max-width: 70%;
        text-align: start;
        line-height: 24px;
        color: $light-color;
        position: relative;
      }
      .message-text:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 100%;
        width: 0;
        height: 0;
        border: 18px solid transparent;
        border-top-color: $dark-color;
        border-bottom: 0;
        border-right: 0;
        margin-left: -18px;
        margin-bottom: -12px;
      }
    }
    .typeMessage-container {
      width: 100%;
      display: grid;
      grid-template-columns: auto 234px;
      column-gap: 3%;
      margin-top: 40px;
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      .responsive-btn {
        display: none;
      }
      input {
        height: 100%;
        background-color: $background-color;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .concept-chat-block {
    .message-container {
      margin-right: 18px;
      padding-left: 8px;
      .typeMessage-container {
        grid-column-gap: 0;
        column-gap: 0;
      }
    }
  }
}

@media (max-width: 860px) {
  .communication-container {
    padding-bottom: 100px;
    .message-container {
      .typeMessage-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .inputWrapper {
          width: 90%;
        }
        .btnWrapper {
          display: none;
        }
        .responsive-btn {
          display: block;
          width: 9%;
          height: 100%;
          .smallButton {
            height: 100%;
            padding: 11px 16px;
            svg {
              fill: white;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .chat-header {
    .chat-mobile-icon {
      display: block !important;
      margin-right: 10px;
    }
  }
  .detailed-info-wrapper {
    .communication-container {
      display: flex;
      width: 100%;
      .message-container {
        display: none;
        &.open {
          padding: 0;
          display: block;
          position: fixed;
          width: 100%;
          height: 100%;
          top: -52px;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: white;
          z-index: 2;
          cursor: pointer;
          .message-list {
            height: 82%;
            padding: 0 16px;
          }
          .typeMessage-container {
            background: #fbfbfb;
            box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08),
              0px -2px 8px rgba(0, 0, 0, 0.04);
            padding: 16px;
            height: 8%;
            margin-top: 0;
            .responsive-btn {
              width: auto;
              height: auto;
              padding-left: 5px;
            }
          }
          .chat-header {
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08),
              0px 2px 8px rgba(0, 0, 0, 0.04);
            height: 56px;
            padding: 20px;
            height: 6%;
            .description {
              display: none;
            }
          }
        }
      }
      .navigation-container {
        border: none;
        .selected {
          border: none;
        }
      }
    }
  }
}

@media (max-width: 700px) and (orientation: landscape) {
  .detailed-info-wrapper {
    .communication-container {
      .message-container {
        &.open {
          .message-list {
            height: 65%;
          }
          .typeMessage-container {
            height: 15%;
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .detailed-info-wrapper {
    .communication-container {
      .message-container {
        display: none;
        &.open {
          top: -52px;
          .message-list {
            height: 72%;
          }
          .typeMessage-container {
            height: auto;
            margin: 0;
            padding: 5px;
            width: 100%;
          }
          .chat-header {
            height: auto;
            padding: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 570px) and (orientation: landscape) {
  .detailed-info-wrapper {
    .communication-container {
      .message-container {
        &.open {
          .message-list {
            height: 62%;
          }
          .typeMessage-container {
            height: 15%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .concept-chat-block {
    .button {
      width: 100px;
    }
    .message-container {
      .typeMessage-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .btnWrapper {
          display: none;
        }
        .responsive-btn {
          display: block;
          .smallButton {
            height: 48px;
          }
        }
      }
    }
  }
}
