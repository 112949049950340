.finish-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  .close {
    top: -15px;
    right: 25px;
  }

  * {
    margin: 20px 0px;
    text-align: center;
  }
  h1 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.8px;
  }
  p {
    // width: 30%;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    margin: 0px;
  }
  .project-name {
    font-weight: bold;
  }
  .btnWrapper {
    margin: 0;
    margin-top: 56px;
    button {
      width: 140px;
    }
  }
}
