@import "./shared-variables.scss";

.countWrapper {
  width: 94px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .countBtn {
    padding: 0;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  .count {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.5px;
    color: $dark-color;
  }
}
