@import '.././shared-variables.scss';
.concept {
  &-wrapper {
    // margin: 0 60px;
    padding-bottom: $basicFooter;
  }
  &-header {
    border-bottom: 1px solid $card-border;
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    line-height: 28px;

    &-name {
      font-weight: 600;
      font-size: 20px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin-left: 60px;
      margin-right: 147px;
    }
    &-architekt,
    &-upload-date {
      *:first-child {
        font-size: 11px;
        vertical-align: middle;
        color: $subtitle-color;
      }
    }
    &-architekt {
      margin-right: 32px;
      *:last-child {
        font-size: 16px;
        letter-spacing: 0.4px;
        text-decoration-line: underline;
        text-transform: capitalize;
        margin-left: 8px;
      }
      &.winner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        text-align: start;
        margin-right: 30px;
      }
    }
    &-upload-date {
      *:last-child {
        font-size: 14px;
        margin-left: 8px;
        line-height: 20px;
      }
      &.winner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        text-align: start;
      }
    }
    .center-side,
    .right-side {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 60px;
    }
    .star-rating {
      margin-right: 135px;
    }
  }
  &-body {
    display: flex;
  }
  &-describe {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 44px;
    padding-left: 0;
    padding-bottom: 0;
    margin-left: 60px;
    border-right: 1px solid $card-border;
    & > div {
      margin-top: 44px;
    }
    &-image {
      width: 268px;
      padding: 24px;
      background-color: $light-color;
      border-radius: 4px;
      border: 1px solid $card-border;
      max-width: fit-content;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-attached-files {
      width: 100%;
      .concept-demo {
        width: 100%;
      }
    }
    &-description {
      .title {
        font-weight: 600;
        font-size: 19px;
        line-height: 24px;
        letter-spacing: 0.5px;
        margin: 32px 0 16px;
      }
      p {
        text-align: left;
      }
    }
    &-description,
    &-attached-files {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      & div:nth-child(2n) {
        background: $background-color;
      }
      & div:nth-child(odd) {
        background: $light-color;
      }
    }
    &-winner-button {
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
    img {
      cursor: default;
    }
  }
  &-describe,
  &-chat-block {
    width: 50%;
  }
}

@media screen and (max-width: 1000px) {
  .concept {
    &-header {
      padding-left: 16px;
      .concept-header-name {
        margin-right: 50px;
      }
      .right-side {
        .star-rating {
          margin-right: 32px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .header-additional {
    padding: 42px 16px 0;
    flex-direction: column-reverse;
    &-right-side {
      margin-right: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .general-info {
        justify-content: flex-start;
        width: 100%;
      }
    }
    &-left-side {
      margin-left: 0;
      margin-top: 32px;
    }
  }
  .concept {
    &-header {
      padding: 16px;
      flex-direction: column;
      position: relative;
      &-name {
        line-height: 28px;
        margin-left: 0;
        margin-right: 0;
      }
      .center-side {
        justify-content: start;
        align-items: flex-start;
        padding-right: 30px;
        .concept-header-upload-date,
        .concept-header-architekt {
          display: flex;
          flex-direction: column;
          &:last-child {
            margin-left: 0;
          }
        }
      }
      .right-side {
        padding: 0 16px;
        justify-content: start;
        margin-top: 16px;
        .back-to-list {
          position: absolute;
          top: 10px;
          right: 10px;
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
      .star-rating {
        padding-left: 0;
        margin-right: 0;
      }
    }
    &-wrapper {
      padding-bottom: 20px;
    }
    &-describe {
      margin: 0 auto;
      padding: 0 16px;
    }
    &-chat-block {
      svg {
        fill: $light-color;
        &:hover {
          fill: $dark-color;
        }
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .concept {
    &-wrapper {
      padding-bottom: 10px;
    }
    &-header {
      border-bottom: none;
      flex-direction: column;
      align-items: baseline;
      .concept-header-name {
        margin-bottom: 32px;
      }
      .right-side {
        padding: 0;
      }
    }
    &-chat-block {
      display: none;
    }
    &-body {
      flex-direction: column;
    }
    &-describe {
      width: auto;
      border-right: none;
      margin-left: 16px;
      margin-right: 16px;
      padding: 0;
      div {
        margin-top: 0;
      }
      &-image {
        margin-bottom: 32px;
      }
    }
  }
}
