.close {
  position: absolute;
  top: 28px;
  right: 28px;
  img {
    width: 33px;
    height: 33px;
  }
}
.submit-concept-wrapper {
  margin: 56px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Source Sans Pro';
  text-align: center;
  font-style: normal;
  font-weight: normal;
  background-color: $light-color;
  min-height: 652px;
  .error-be {
    padding: 16px;
    min-height: 46px;
    color: #eb5757;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
  }
  .main-title {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.8px;
    color: $dark-color;
    margin-bottom: 40px;
  }
  .submit-concept-form {
    width: 368px;
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: $dark-color;
      text-align: left;
    }
    .sub-title {
      color: $dark-color;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: $subtitle-color;
      margin-top: 16px;
      text-align: left;
    }
    textarea {
      font-style: normal;
      font-weight: normal;
      width: 100%;
      border: 1px solid $light-gray;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 14px 16px;
      font-size: 14px;
      line-height: 20px;
      background: $light-color;
      height: 96px;
      resize: none;
      overflow: auto;
      &:focus,
      &:active {
        outline: 0;
        border: 1px solid $dark-color;
        border-radius: 4px;
      }
      &.error {
        border-color: #eb5757;
      }
    }
    .file-wrapper {
      margin: 16px 0 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 58px;
      #loading_concept,
      #loading_preview {
        opacity: 0;
        position: absolute;
        z-index: -1;
        top: -9999px;
        left: -9999px;
      }
      label {
        background: transparent;
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: $dark-color;
        letter-spacing: 1px;
        span {
          border-bottom: 1px solid $dark-color;
          margin-right: 12px;
        }
      }
    }
    .file-list {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      .item-file {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $dark-color;
      }
      & div:nth-child(2n) {
        background: $background-color;
      }
      & div:nth-child(odd) {
        background: $card-border;
      }
    }
    .btnWrapper {
      margin-top: 32px;
    }
    .error {
      color: #eb5757;
      font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
      text-align: left;
      min-height: 30px;
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 320px) {
  .submit-concept-wrapper {
    padding: 16px;
    .main-title {
      font-size: 30px;
    }
    .submit-concept-form {
      width: auto;
    }
  }
}
