.fourth-step {
  .info-block-money_back, .info-block {
    background-color: $light-color;
    border: 1px solid $card-border;
    border-radius: $border-radius;
    padding: 40px;
    width: 100%;
    &-item {
      display: flex;
      font-size: 16px;
      justify-content: space-between;
      line-height: 24px;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
      &.block-header {
        color: $subtitle-color;
        font-size: 11px;
        line-height: 12px;
        margin-bottom: 24px;
      }
      &.extra-tariff{
        margin-left: 1rem;
      }
    }
  }
  .info {
    display: flex;
    flex-direction: row;
    .info-block-money_back {
      border-left: 1px solid #F0F0F0;
      width: 33%;
      text-align: center;
      
      h3 {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
        color: #000000;
        letter-spacing: 0.5px;
        font-family: Source Sans Pro;
      }
  }
  }
  .payment-block {
    .comment {
      font-size: 12px;
      line-height: 16px;
      color: $gray-color;
      width: 60%;
    }
    .list-item-container {
      justify-content: space-between;
      align-items: center;
      &.two-items-left {
        @include for-phone {
          .children {
            width: 100%;
          }
          .images,
          .comment {
            display: none;
          }
        }
      }
      .children {
        margin-right: 0;
        width: 25%;
      }
    }
    &-card {
      background-color: #ffffff;
      border-radius: 4px;
      border: 1px solid #000000;
      margin-bottom: 32px;
      .list-item-container {
        border: none;
        background-color: initial;
      }
      .images {
        img:last-child {
          margin-left: 25px;
        }
      }
    }
    .line {
      padding-left: 24px;
      padding-right: 24px;
      hr {
        margin: 0;
      }
    }
    .inputWrapper:first-child {
      width: 35%;
      @include for-phone {
        width: 100%;
      }
    }
    .inputWrapper:nth-child(2) {
      width: 35%;
      @include for-phone {
        width: 100%;
      }
    }
    .inputWrapper:nth-child(3) {
      width: 20%;
      @include for-phone {
        width: 100%;
      }
    }
    .inputWrapper:last-child {
      width: 20%;
      @include for-phone {
        width: 100%;
      }
    }
  }
}

.voucher-button{
  min-width: 100px !important;
}

.voucher-wrapper{
  max-width: 50%;
}

@media only screen and (max-width: 769px) {
  .voucher-wrapper{
    max-width: 100%;
  }

  .info {
    flex-direction: column !important;
    .info-block-money_back{
      width: 100% !important;
      margin-top: 1rem;
    }
  }
}