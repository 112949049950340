.card-info-block {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  *:not(:last-child) {
    margin-right: 20px;
    @include for-phone {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  @include for-phone {
    flex-direction: column;
  }
}
