@import './shared-variables.scss';

.cardWrapper {
  width: 168px;
  // height: 216px;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  cursor: pointer;
  @include for-phone-and-tablet {
    width: 150px;
  }
  .imgContainer {
    position: relative;
    background: $light-color;
    border-radius: $border-radius;
    width: 168px;
    min-height: 140px;
    max-height: 168px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $card-border;
    @include for-phone-and-tablet {
      width: 150px;
    }
    .selectable-card-img {
      border-radius: inherit;
      object-fit: none;
      height: 100%;
      width: 100%;
    }
    .garden-img {
      object-fit: cover;
    }
    &.gray-layer {
      filter: grayscale(100%);
    }
    &.checked {
      border: 1px solid $dark-color;
      & + .title,
      .inner-title {
        font-weight: 600;
      }
    }
    &.with-inner-title {
      .selectable-card-img {
        height: 40%;
      }
    }
    .checked {
      position: absolute;
      bottom: -16px;
      left: calc(50% - 16px);
    }
    .cardHasInfo {
      position: absolute;
      top: 2px;
      right: 4px;
      cursor: pointer;
    }
    .inner-title {
      margin-bottom: 22px;
    }
  }
  .garden-img {
    min-height: auto;
  }
  .title {
    min-height: 48px;
    margin-top: 24px;
    margin-bottom: 32px;
  }
}
