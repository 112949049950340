.item-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $dark-color;
  background: $background-color;
  a {
    cursor: pointer;
    color: $dark-color;
    text-decoration: none;
  }
  a:hover {
    border-bottom: 1px solid $dark-color;
  }
}
