@import './shared-variables.scss';

.upsellWrapper {
  background: $light-color;
  border: 1px solid $card-border;
  box-sizing: border-box;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  width: 230px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 20px;
  position: relative;
  &.included {
    background-color: $subtitle-color;
  }
  &.highlight {
    background-color: #cccccc;
  }
  .item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
    text-align: center;
    padding: 20px 10px;
    font-style: normal;
    border: none;
    color: $dark-color;
    &.desc {
      height: 300px;
      justify-content: center;
    }
    &.container{
      height: 40px;
      justify-content: center;
    }
    &.price{
      height: 60px;
      justify-content: center;
    }
    .amount {
    font-weight: bold;
    font-size: 16px;
    line-height: 38px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    text-align: center;
    }
    .tax {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    }
    .title-mobile {
      display: none;
    }
    .title, .title-mobile {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: $dark-color;
    align-self: center;
    text-align: center;
    }
    .description {
        font-style: normal;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.5px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $dark-color;
        text-align: center;
        flex-direction: column;
        align-items: flex-start;
        &.hide {
          display: none;
        }
    }
    .item-container {
      min-height: 60px;
    }
  }
  .additional-info {
    display: none;
    text-align: left;
    font-style: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.5px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: $dark-color;
    flex-direction: column;
    align-items: flex-start;
    li {
      list-style: disc;
      margin-left: 15px;
      margin-bottom: 5px;
    }
    p {
      text-align: center;
    }
  }
  .additional-info.show {
    display: block;
  }
  &.checked {
    border: 1px solid $dark-color;
  }
  .checked {
    position: absolute;
    top: -15px;
    left: calc(50% - 16px);
    z-index: 1;
  }
  @media only screen and (min-width: 769px) {
    .item:hover {
      background-color: #cccccc;
    }
    &.has-info {
      .item:hover {
        .title, .desc-body {
          display: none;
        }
        .title-mobile, .additional-info {
          display: block;
        }
      }
    }
  }
  @media (max-width: 426px) {
    width: 100%;
  }
}

.toggle-info-m {
  padding-bottom: 25px;
  display: none;
}

@media only screen and (max-width: 769px) {
  .toggle-info-m {
    display: block;
  }
}