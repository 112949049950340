@import '.././shared-variables.scss';
.designer-winner {
  background: $dark-color;
  font-size: 26px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: $light-color;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center;
  svg {
    margin-right: 23px;
  }
}
.concept-winner {
  &-wrapper {
    display: flex;
    margin: 0 60px;
    padding-bottom: $basicFooter;
    & > div {
      width: 50%;
    }

    .concept-chat-block {
      .message-container {
        margin-right: 0;
      }
    }
  }
  &-describe {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-right: 1px solid $card-border;
    padding-right: 33px;
    .winner {
      width: 100%;
      border-right: none;
      margin-left: 0;
      padding: 0;
      .concept-describe-attached-files {
        margin-top: 0;
        .uploadFile {
          width: 220px;
          background: $background-color;
          margin-top: 24px;
          input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
          }
          label {
            text-align: left;
            color: $light-color;
            border: 1px solid $light-color;
            background: $dark-color;
            transition: all 0.2s;
            border-radius: 4px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 1px;
            text-transform: uppercase;
            padding: 14px 0px 14px 20px;
            display: flex;
            cursor: pointer;

            svg {
              fill: $light-color;
              margin-left: 12px;
            }
          }
        }
        .uploadFile:hover {
          label {
            color: $dark-color;
            background: $light-color;
            border: 1px solid $dark-color;
          }
          svg {
            fill: $dark-color;
          }
        }
        margin-bottom: 40px;
      }
      .files-title-table {
        margin-top: 44px;
      }
      .concept-describe-description {
        > p {
          text-align: left;
        }
      }
      .files-header {
        display: grid;
        grid-template-columns: 0.9fr 3.1fr 1fr 2fr 1fr;
        justify-items: start;
        background: $card-border;
        color: $subtitle-color;
        padding: 6px 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 12px;
        width: 100%;
        margin-top: 0;
        .file-data {
          justify-self: end;
        }
      }
    }
    .concept-describe-image {
      margin-top: 24px;
    }
    h2 {
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    & > span {
      margin: 44px 0;
    }
  }
  &-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .group {
      display: flex;
      width: 60%;
      .concept-header-architekt.winner {
        width: 30%;
      }

      .concept-header-architekt *:last-child {
        margin-left: 0px;
      }

      .concept-header-upload-date *:last-child {
        margin-left: 0px;
      }
    }
  }
  &-badge {
    align-items: center;
    background-color: $dark-color;
    border-radius: 1px;
    color: $light-color;
    display: flex;
    justify-content: space-between;
    padding: 4px 20px;
    height: 32px;
    width: 140px;
  }
}

.isDesigner-true {
  .concept-winner-header {
    flex-direction: row;
    justify-content: space-between;
  }
  .concept-header-name {
    text-align: left;
    width: 50%;
  }

  .concept-winner-badge {
    display: none;
  }
  .concept-winner-info {
    width: 50%;
  }
  .group {
    width: 100%;
    .concept-header-architekt.winner {
      display: none;
    }
    .concept-header-upload-date.winner {
      margin: 44px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
@media screen and (max-width: 768px) {
  .header-additional {
    padding: 42px 16px 0;
    flex-direction: column-reverse;
    &-right-side {
      margin-right: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .general-info {
        justify-content: flex-start;
        width: 100%;
      }
    }
    &-left-side {
      margin-left: 0;
      margin-top: 32px;
    }
  }
  .profile {
    .detailed-info-wrapper {
      .concept-winner-wrapper {
        padding: 20px 18px;
        margin: 0;
        .concept-winner-header {
          .concept-header-name {
            margin-top: 0;
          }
          .concept-winner-info {
            flex-direction: column;
            .group {
              margin-bottom: 12px;
            }
          }
        }
        .concept-winner-describe {
          margin: 0 auto;
          padding: 0 16px 44px;
          .concept-describe.winner {
            .files-header {
              .format,
              .file-data {
                display: none;
              }
            }
          }
        }
        .concept-chat-block {
          .message-container {
            margin-top: 0;
            .chat-header {
              .title {
                flex-direction: column;
              }
              .description {
                width: 62%;
              }
            }
          }
          svg {
            fill: $light-color;
            &:hover {
              fill: $dark-color;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .profile {
    .detailed-info-wrapper {
      .concept-winner-wrapper {
        .concept-winner-describe {
          padding: 0;
          margin: 0;
          width: 100%;
          border-right: none;
          .concept-winner-header {
            .concept-winner-info {
              .group {
                width: auto;
              }
            }
          }
        }
      }
    }
  }
  .designer-winner {
    font-size: 21px;
    svg {
      display: none;
    }
  }
}

@media screen and (max-width: 375px) {
  .designer-winner {
    font-size: 18px;
  }
}

@media screen and (max-width: 320px) {
  .designer-winner {
    font-size: 15px;
  }
}
