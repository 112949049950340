@import './shared-variables.scss';

.page-wrapper {
  width: 85%;
  margin: 56px auto auto;
  display: flex;
  flex-direction: column;
  .projectWrapper {
    text-align: left;
    h1 {
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.8px;
      color: $dark-color;
    }
    .table-wrapper {
      margin-top: 40px;
      ul {
        .tableHeader {
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 12px;
          font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
          color: $subtitle-color;
          background: $card-border;
          border-radius: 4px;
          padding: 6px 24px;
          display: grid;
          grid-template-rows: 1fr;
          // grid-template-columns: 52px 1fr 0.5fr 70px 80px 80px 44px;
          grid-template-columns: 52px 1fr 0.5fr 70px 80px 80px 30px 60px;
          grid-gap: 24px;
          align-items: center;
          @include for-phone-and-tablet {
            display: none;
          }
          span {
            text-align: left;
          }
          // .category {
          //   padding-left: 20%;
          // }
        }
        .table-item {
          background: $light-color;
          border: 1px solid $card-border;
          box-sizing: border-box;
          border-radius: 4px;
          color: $dark-color;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          padding: 20px 30px 25px;
          margin-top: 4px;
          display: grid;
          grid-template-rows: 1fr;
          // grid-template-columns: 52px 1fr 0.5fr 70px 80px 80px 44px;
          grid-template-columns: 52px 1fr 0.5fr 70px 80px 80px 45px 40px;
          grid-gap: 24px;
          align-items: center;
          @include for-phone-and-tablet {
            border: 2px solid $card-border;
            grid-template-columns: 1fr;
            .img,
            .status {
              display: none;
            }
            div {
              display: grid;
              grid-template-rows: 1fr;
              grid-template-columns: 0.5fr 1fr;
            }
          }
          .mobile-view {
            align-self: center;
            display: none;
            color: $subtitle-color;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 12px;
            @include for-phone-and-tablet {
              display: block;
              
              &.amount-block {
                display: flex;
                order: -1;
                justify-content: space-between;
                color: $dark-color;
              }
              .img,
              .status {
                display: block;
              }
            }
            .pagination {
              justify-content: center;
            }
          }
          span {
            text-align: left;
            text-transform: capitalize;
          }
          .img {
            text-align: right;
            cursor: pointer;
          }
          .bold {
            font-weight: 600;
          }
          .gray {
            color: $subtitle-color;
          }
          .status {
            text-transform: lowercase;
            text-align: center;
            font-size: 11px;
            line-height: 20px;
            letter-spacing: 0.4px;
            border-radius: 1px;
            padding: 2px 4px;
          }
          .entwurf {
            color: #e86d00;
            background: #fcede0;
          }
          .faberling {
            color: #e80000;
            background: #fce0e0;
          }
          .faberling-coach {
            color: #e80000;
            background: #fce0e0;
          }
          .laufend {
            color: #219653;
            background: #e4f2ea;
          }
          .genehmigt,
          .abgesagt {
            background: $card-border;
          }
        }
      }
    }
    .create-project {
      display: none;
    }
    @media screen and (max-width: 701px) {
      .create-project {
        display: inline-block;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        // width: 61.25%;
        margin-left: 0;
        margin-right: 0;
        margin-top: 25px;
        a {
          background: $dark-color;
          color: $light-color;
          display: flex;
          justify-content: center;
          padding: 16px;
          border-radius: 4px;
          svg {
            margin-left: 11px;
          }
        }
      }
    }
    @media screen and (max-width: 426px) {
      .create-project {
        display: block;
      }
    }
  }
  .designer-page {
    .table-wrapper {
      ul {
        .tableHeader {
          grid-template-columns: 0.3fr 0.6fr 1fr 1fr 1.4fr 0.5fr 1fr 1.2fr 0.6fr 0.2fr;
          grid-gap: 0;
          @include for-phone-and-tablet {
            display: none;
          }
        }
        .table-item {
          padding: 32px 23px;
          grid-template-columns: 0.3fr 0.6fr 1fr 1fr 1.4fr 0.5fr 1fr 1.2fr 0.6fr 0.2fr;
          grid-gap: 0;
          justify-content: center;
          .order-first {
            order: unset;
          }
          @include for-phone-and-tablet {
            border: 2px solid $card-border;
            grid-template-columns: 1fr;
            .favorite-img,
            .rating,
            .img {
              display: none;
            }
            .order {
              &-first {
                order: 1;
              }
              &-second {
                order: 2;
              }
              &-third {
                order: 3;
              }
              &-fourth {
                order: 4;
              }
              &-fifth {
                order: 5;
              }
              &-sixth {
                order: 6;
              }
              &-seventh {
                order: 7;
              }
            }
            div {
              display: grid;
              grid-template-rows: 1fr;
              grid-template-columns: 0.5fr 1fr;
            }
            .rating {
              .star-rating {
                display: flex;
                label {
                  margin-right: 7px;
                }
              }
            }
          }
          .mobile-view {
            align-self: center;
            display: none;
            color: $subtitle-color;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 12px;
            @include for-phone-and-tablet {
              display: block;
              &.amount-block {
                display: flex;
                order: -1;
                justify-content: space-between;
                color: $dark-color;
                margin-bottom: 7px;
              }
              .favorite-img,
              .rating,
              .img {
                display: block;
              }
            }
          }
        }
      }
      span {
        text-align: left;
      }
    }
    .rating {
      .star-rating {
        display: flex;
      }
    }
  }
}
