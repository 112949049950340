@import './shared-variables.scss';
.forgot-pass-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .submit-winner-concept-wrapper__content {
    width: 100%;
  }
  .btnWrapper {
    margin: 0 auto;
    // width: 25%;
    padding-bottom: 30px;
    .button{
      width: 25%
    }
  }
}
.submit-winner-concept-wrapper, .modal-error {
  .close {
    margin-top: 27px;
    margin-right: 27px;
    img {
      width: 33px;
    }
  }

  &__content {
    margin-top: 88px;
    text-align: center;
    svg {
      margin-bottom: 32px;
    }
    h1 {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.8px;
      margin-bottom: 24px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.4px;
      margin-bottom: 56px;
    }

    .buttons {
      display: flex;
      justify-content: center;
      margin-bottom: 356px;
      button {
        width: 140px;
        height: 52px;
      }
      .cancel {
        .btnActive {
          color: $dark-color;
          border: 1px solid $dark-color;
          background: $light-color;
          transition: all 0.2s;
          margin-left: 32px;
        }
        .btnActive:hover {
          color: $light-color;
          border: 1px solid $light-color;
          background: $dark-color;
          transition: all 0.2s;
        }
      }
    }
  }
}
.modal-error {
  .buttons {
    margin: 10px 0;
  }
}
