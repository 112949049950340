.comment-block-wrapper {
  display: flex;
  flex-direction: column;
  background-color: $light-color;
  padding: 24px;
  margin-bottom: 4px;
  border: 1px solid $card-border;
  border-radius: $border-radius;
  .title-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    position: relative;
  }
  .title-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
  .tooltip-arrow {
    display: none;
    position: absolute;
    top: 3px;
    right: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid $dark-color;
  }
  textarea {
    font-style: normal;
    font-weight: normal;
    width: 100%;
    border: 1px solid $light-gray;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 14px 16px;
    font-size: 14px;
    line-height: 20px;
    background: $light-color;
    height: 96px;
    resize: none;
    overflow: auto;
    &:focus,
    &:active {
      outline: 0;
      border: 1px solid $dark-color;
      border-radius: 4px;
    }
    &.error {
      border-color: #eb5757;
    }
    &::placeholder {
      color: $subtitle-color;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
