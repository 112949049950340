@import './shared-variables.scss';

.basic-info-page {
  padding-bottom: $basicFooter;
  @media (max-width: 768px) {
    padding-bottom: $mobileFooter;
  }
  @media (max-width: 320px) {
    padding-bottom: 0;
  }
  .page-title {
    font-size: 32px;
    letter-spacing: 0.8px;
    margin-bottom: 24px;
    &.article {
      margin-bottom: 16px;
    }
  }
  .page-description,
  .content-container {
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.4px;
    &.article {
      margin-top: 16px;
      margin-bottom: 56px;
    }
    h5 {
      font-weight: 600;
      font-size: 23px;
      line-height: 28px;
      margin-bottom: 32px;
    }
    img {
      margin-bottom: 32px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 32px;
    }
    li,
    ul,
    ol {
      list-style: initial;
      padding: inherit;
      padding: 0 20px;
      font-size: 16px;
      line-height: 24px;
    }
    ol {
      li {
        list-style: decimal;
      }
    }
    h6 {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
    }
  }
  .item-title {
    font-weight: bold;
    font-size: 20px;
    margin: 16px 0;
    letter-spacing: 0.5px;
    line-height: 28px;
    &.uppercase {
      text-transform: uppercase;
    }
    &.article {
      margin: 32px 0;
    }
  }
  .item-subtitle {
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin: 32px 0;
  }
  .item-description {
    line-height: 24px;
    letter-spacing: 0.4px;
  }
  .item-date {
    font-size: 12px;
    line-height: 16px;
    color: $subtitle-color;
    &.article {
      font-size: 16px;
    }
  }
  ul {
    list-style-position: inside;
    margin: 32px 0;
    li {
      line-height: 24px;
      margin-bottom: 16px;
    }
    li:last-child {
      margin-bottom: 0px;
    }
  }
  a {
    color: $dark-color;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  }
  .default-text {
    margin-bottom: 32px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }
  .container-wrapper {
    width: 100%;
    height: 100%;
    margin-top: 3px;
    padding-top: 56px;
    padding-bottom: 80px;
    text-align: left;
    position: relative;
    &.light {
      background: $light-color;
    }
    &.article {
      padding-bottom: 136px;
    }
    .content-container {
      width: 768px;
      margin: 0 auto;
      .step {
        margin-top: 56px;
        position: relative;
        overflow: hidden;
        min-height: 338px;
        .step-content {
          width: 45%;
          float: right;
        }
        figure {
          position: absolute;
          top: 0;
          left: 0;
        }
        img {
          margin-right: 64px;
          margin-left: 0;
          min-width: 336px;
          width: 336px;
          height: 336px;
        }
        .no {
          width: 32px;
          height: 32px;
        }
        // &.reverse {
        //   flex-direction: row-reverse;
        //   img {
        //     margin-left: 64px;
        //     margin-right: 0;
        //   }
        // }
        .default-text:last-child {
          margin-bottom: 0;
        }
        &.reverse {
          .step-content {
            float: left;
          }
          figure {
            position: absolute;
            top: 0;
            right: 0;
            left: auto;
          }
        }
      }
      .tariffs {
        display: flex;
        justify-content: space-between;
        margin-top: 56px;
        .tariffWrapper {
          padding-top: 0px;
          .item {
            &.top {
              margin: 24px 0;
            }
          }
        }
      }
      .additional-info {
        .item {
          margin-top: 56px;
        }
      }
      .btnWrapper {
        text-align: center;
        margin: 56px auto 0 auto;
        width: 216px;
      }
      .apply {
        margin-bottom: 113px;
        .btnWrapper {
          width: 256px;
        }
      }
      .paragraph {
        margin-top: 56px;
      }
      .buying-process,
      .branding_1 ul {
        list-style-type: disc;
      }
      .spheres,
      .branding_2 ul {
        list-style-type: decimal;
        margin-bottom: 0;
      }
      .no {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $dark-color;
        border-radius: $border-radius;
        color: $light-color;
        font-weight: bold;
      }
    }
  }
  .blog-container {
    width: 100%;
    height: 100%;
    margin-top: 3px;
    padding: 56px 80px 80px 80px;
    text-align: left;
    .page-title {
      margin-bottom: 40px;
      span {
        color: $subtitle-color;
      }
    }

    .articles {
      display: grid;
      grid-template-columns: 30% 30% 30%;
      grid-column-gap: 5%;
      .article {
        padding: 24px;
        margin-bottom: 32px;
        background: $light-color;
        border: 1px solid $card-border;
        box-sizing: border-box;
        border-radius: $border-radius;
        overflow: hidden;
        max-height: 555px;
        max-width: 368px;
        img {
          margin-top: 24px;
          max-height: 100%;
          max-width: 100%;
        }
        .item-title {
          overflow: hidden;
        }
      }
      .article:hover {
        border: 1px solid $dark-color;
      }
    }
    .btnWrapper {
      button {
        background: $card-border;
        border: 1px solid $light-gray;
        color: $dark-color;
        text-transform: capitalize;
        letter-spacing: 0.5px;
      }
    }
  }
  .routes {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 32px;
    .article-title {
      color: $subtitle-color;
    }
    img {
      margin: 0 10px;
    }
    a {
      color: $dark-color;
    }
  }
  .change-article {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    margin: 56px 0;
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      height: 24px;
      img {
        margin: 0 12px;
      }
    }
  }
  @media (max-width: 768px) {
    .page-title,
    .page-description {
      padding: 0 16px;
      &.article {
        padding: 0;
      }
    }
    .container-wrapper {
      padding-bottom: 0;
      &.article {
        padding-right: 16px;
        padding-left: 16px;
      }
      .content-container {
        margin: 0;
        width: 100%;
        overflow-x: hidden;
        .step {
          display: flex;
          flex-direction: column;
          width: 100%;
          background: $light-color;
          padding: 48px 16px;
          margin-top: 4px;
          img,
          // &.reverse img {
          //   display: flex;
          //   min-width: 288px;
          //   min-height: 288px;
          //   max-width: 100%;
          //   height: 100%;
          //   margin: 0 auto 16px auto;
          // }
          // &.reverse {
          //   flex-direction: column;
          // }
          &:first-child {
            margin-top: 52px;
          }
          .step-content {
            width: 100%;
            float: none;
          }
          figure {
            position: relative;
          }
          &.reverse {
            .step-content {
              float: none;
            }
            figure {
              position: relative;
            }
          }
        }
        .tariffs {
          margin-top: 0;
          display: flex;
          overflow-x: auto;
          .tariffWrapper {
            margin: 0;
            &.Basis {
              margin: 0 12px;
            }
          }
        }
        .additional-info {
          padding: 0 16px;
        }
      }
    }
    .blog-container {
      padding: 56px 0 0 0;
      .articles {
        display: flex;
        flex-direction: column;
        .article {
          padding: 16px;
          margin-bottom: 4px;
          img {
            display: flex;
            margin: 24px auto 0 auto;
          }
        }
      }
      .btnWrapper {
        margin-top: 48px;
        padding: 0 16px;
      }
    }
    .faqWrapper {
      .item-title {
        margin: 56px 0 32px 0;
      }
    }
  }
}
