/*COMMON*/
$border-radius: 4px;
$main-font: 'Source Sans Pro';
$basicFooter: 432px;
$projectFooter: 80px;
$mobileFooter: 666px;
/*COLORS*/
$dark-color: #000000;
$light-color: #ffffff;
$gray-color: rgba(0, 0, 0, 0.4);
$light-gray-color: rgba(0, 0, 0, 0.04);
$shadow-gray: rgba(0, 0, 0, 0.25);
$background-color: #fbfbfb;
$card-border: #f0f0f0;
$light-gray: #d9d9d9;
$subtitle-color: #999999;
$disable-color: #e5e5e5;
$info-color: #1586ee;
$success-color: #13ac55;
$warning-color: #f68500;
$error-color: #e54848;
