@import './shared-variables.scss';
.star-rating {
  &__star {
    margin-right: 12px;
    &:hover {
      cursor: pointer;
    }
  }

  &__star.false {
    &:hover {
      cursor: default;
    }
  }

  &__star:last-child {
    margin-right: 0;
  }

  .is-selected svg {
    fill: $dark-color;
  }
}
