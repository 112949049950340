.drop-zone {
  margin-top: 32px;
  position: relative;
  background: $light-color;
  border: 1px solid $light-gray;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px;
  .label {
    position: absolute;
    top: -7px;
    left: 16px;
    z-index: 99;
    padding: 0 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    color: $gray-color;
    background: $light-color;
    padding: 0 4px;
    &.error-color {
      color: #eb5757;
    }
  }
  .upload-file {
    width: 100%;
    background-color: $light-color;
    position: relative;
    display: inline-block;
    .upload-file-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      #main_document_id,
      #additional_document_id {
        opacity: 0;
        position: absolute;
        z-index: -1;
        top: -9999px;
        left: -9999px;
      }
      label {
        background: $dark-color;
        border-radius: 4px;
        padding: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: $light-color;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
      }
    }
    .drop-here {
      background-color: rgba(255, 255, 255, 0.8);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9999;
      &-text {
        position: absolute;
        top: 25%;
        left: 0;
        right: 0;
        text-align: center;
        color: $subtitle-color;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
      }
    }
    h3,
    h4 {
      text-align: center;
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $subtitle-color;
    }
  }
  .upload-file:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '\00a0';
    position: absolute;
    pointer-events: none;
  }
  .upload-file:hover:after {
    transition: border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .upload-file-info {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
      color: $dark-color;
    }
    .upload-file-info-container {
      display: flex;
      align-items: center;
      width: 100%;
      .upload-file-info-button {
        margin-left: auto;
        .btn-change {
          background: $dark-color;
          border-radius: 4px;
          padding: 14px;
          border: none;
          cursor: pointer;
          color: $light-color;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          text-transform: uppercase;
        }
        &:active,
        :hover {
          outline: none;
          border: none;
        }
        #identity_document {
          opacity: 0;
          position: absolute;
          z-index: -1;
        }
      }
    }
  }
  .progress-container {
    p {
      color: $dark-color;
      position: absolute;
      z-index: 99999;
      top: 25px;
      left: calc(50% - 16px);
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 20px;
    }
    .progress {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '\00a0';
      position: absolute;
      pointer-events: none;
      background-color: $light-color;
      opacity: 0.9;
      border-radius: 4px;
      padding: 15px;
      height: auto;
    }
  }
  &.error-color {
    border-color: #eb5757;
  }
}
.messages {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  min-height: 76px;
  .error {
    color: #eb5757;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    display: flex;
    align-items: center;
    padding: 10px 10px 0;
  }
  .helper {
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    width: 100%;
    color: #999999;
    padding: 10px;
  }
}
