.checkBoxWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  .container {
    padding-right: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $dark-color;
    padding-left: 40px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked ~ .checkmark {
        background-color: $dark-color;
        &:after {
          display: block;
        }
      }
      &:disabled ~ .checkmark {
        background-color: $subtitle-color;
        border-color: $subtitle-color;
      }
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      background: $light-color;
      border: 1px solid $dark-color;
      box-sizing: border-box;
      border-radius: 4px;
      &.round-checkmark {
        border-radius: 50%;
      }
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 9px;
        border: 1px solid $light-color;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    .big {
      top: -4px;
      height: 32px;
      width: 32px;
      &:after {
        width: 9px;
        height: 12px;
        border: 1.5px solid $light-color;
        border-width: 0 2px 2px 0;
        left: 10px;
        top: 6px;
      }
    }
  }
  .subcsribe {
    margin: 0 !important;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    padding-right: 0px;
    strong {
      text-decoration: underline;
    }
  }
}
