@import './shared-variables.scss';

.loginWrapper {
  font-family: 'Source Sans Pro';
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid $card-border;
  box-sizing: border-box;
  border-radius: 4px;
  background: $light-color;
  .leftContainer {
    position: relative;
    background: $background-color;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    border: 1px solid $card-border;
    .logoFaberling {
      position: absolute;
      width: 143px;
      height: 24px;
      left: 67px;
      top: 32px;
      cursor: default;
    }
    .loginChangeContainer {
      background: $light-color;
      border: 1px solid $card-border;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 40px;
      height: 240px;
      width: 268px;
      margin: 0 auto 56px;
      text-align: left;
      font-style: normal;
      .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.5px;
        margin-bottom: 12px;
        text-align: inherit;
      }
      .subTitle {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $dark-color;
        margin-bottom: 24px;
        text-align: inherit;
        min-height: 60px;
      }
    }
    .callUsContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 40px;
      background: $background-color;
      border: 1px solid $card-border;
      box-sizing: border-box;
      border-radius: 4px;
      margin: 0 auto;
      font-style: normal;
      color: $dark-color;
      width: 268px;
      text-align: left;
      align-items: flex-start;
      .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.5px;
        margin-bottom: 12px;
      }
      .subTitle {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $gray-color;
        margin-bottom: 16px;
      }
      .phone {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.4px;
        margin-top: 12px;
      }
    }
  }
  .rightContainer {
    position: relative;
    width: 100%;
    background: $light-color;
    .mobileLogo {
      display: none;
    }
    .close {
      position: absolute;
      right: 24px;
      top: 24px;
      cursor: pointer;
    }
    .formContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      .form-wrapper {
        font-family: 'Source Sans Pro';
        text-align: center;
        width: 368px;
        font-style: normal;
        font-weight: normal;
        .title {
          font-size: 32px;
          line-height: 40px;
          letter-spacing: 0.8px;
          color: $dark-color;
        }
        .subTitle {
          color: $dark-color;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.4px;
          color: $subtitle-color;
          margin-top: 14px;
        }
        .auth-form {
          width: 100%;
          .error-be {
            min-height: 14px;
            margin-top: 32px;
            color: #eb5757;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
          }
          .inputWrapper {
            margin-top: 32px;
          }
          .helper {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 32px 0;
            a {
              width: max-content;
              letter-spacing: 0.5px;
              font-feature-settings: 'pnum' on, 'lnum' on;
              font-size: 16px;
              line-height: 24px;
              color: $dark-color;
              border-bottom: 1px solid $dark-color;
              padding-bottom: 13px;
            }
            a:hover {
              text-decoration: none;
            }
          }
        }
        .registration-form {
          .error-be {
            min-height: 14px;
            margin-top: 32px;
            color: #eb5757;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
          }
          .small-inputs {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .inputWrapper {
              width: 47%;
            }
          }
          .inputWrapper {
            margin-top: 32px;
          }
          .subscribeBox {
            margin: 32px 0;
            & div:first-child {
              margin-bottom: 24px;
            }
          }
          .btnWrapper {
            margin: 32px auto;
          }
          .relationship {
            margin: 32px 0 56px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: 1fr 1fr;
            grid-column-gap: 50px;
            grid-row-gap: 25px;
            padding-left: 32px;
          }
        }
        .forgotPassForm {
          .inputWrapper {
            margin: 32px 0;
          }
        }
        .page-switcher {
          margin-top: 44px;
          button {
            background-color: $light-color;
            border: 1px solid $dark-color;
            border-right: none;
            cursor: pointer;
            outline: none;
            padding: 14px 20px;
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            // &:hover {
            //   background: $card-border;
            //   color: $dark-color;
            //   text-decoration: underline;
            // }
          }

          button:first-child {
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
          }
          button:last-child {
            border-bottom-right-radius: 4px;
            border-right: 1px solid $dark-color;
            border-top-right-radius: 4px;
          }

          .active {
            background-color: $dark-color;
            color: $light-color;
          }
        }
      }
      .registration {
        min-height: 950px;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .loginWrapper {
    flex-direction: column-reverse;
    align-items: center;
    .leftContainer {
      width: 100%;
      padding: 16px;
      flex-direction: row;
      .logoFaberling {
        display: none;
      }
      .loginChangeContainer,
      .callUsContainer {
        // width: auto;
        width: 48%;
        height: 241px;
        padding: 24px;
        border-radius: unset;
        .subTitle {
          margin-bottom: 0;
        }
      }
      .loginChangeContainer {
        // margin: 0 auto 32px;
        margin: 0;
        .smallButton {
          margin-top: 8px;
        }
      }
    }
    .rightContainer {
        .mobileLogo {
          display: block;
          margin: 20px 0;
          align-self: flex-start;
        }
      .formContainer {
        padding: 32px 0;
        .form-wrapper {
          width: 368px;
          text-align: left;
          .subTitle {
            text-align: left;
          }
          .auth-form {
            .error-be {
              font-size: 10px;
              margin-top: 20px;
            }
            .inputWrapper {
              margin-top: 24px;
            }
          }
        }
        .registration {
          .page-switcher {
            margin-top: 32px;
            button {
              padding: 14px;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: 0.3px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .loginWrapper {
    .leftContainer {
      display: block;
      width: 100%;
      .loginChangeContainer {
        margin-bottom: 15px;
      }
      .loginChangeContainer,
      .callUsContainer {
        width: 100%;
      }
    }
    .rightContainer {
      .formContainer {
        .form-wrapper {
          width: 330px;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .loginWrapper {
    .leftContainer {
      width: auto;
      // .loginChangeContainer,
      // .callUsContainer {
      //   width: 288px;
      // }
    }
    .rightContainer {
      .formContainer {
        .form-wrapper {
          width: 288px;
        }
      }
    }
  }
}
